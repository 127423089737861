import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterLinkActive } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit
{ 
  public Autenticado: boolean = false;
  public loading: boolean = true;
  private paginasSinLoging: string[] = ["/", 
  "/registro-participante", 
  "/nuevo-participante",
  //"/listado-eventos",
  "/detalle-evento",
  "/servicios-evento",
  "/pagar-servicio",
  "/listado-favoritos",
  "/listado-reservas",
  //"/perfil-usuario",
  "/diseno-listado-eventos"];

  constructor(/*private router: Router, private activedrouter: ActivatedRoute*/) { 

    /*this.loading = false;

    router.events.pipe(
      filter(event => event instanceof NavigationEnd)
  )
      .subscribe((event: any) => {
          console.log(event);
          const temp = event.url.split('?')[0];

          this.Autenticado = !this.paginasSinLoging.includes(temp);
      });*/
  }

  ngOnInit(): void 
  {
  } 
}
