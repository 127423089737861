export class ListadoCategorias {
    
    Id: string;
    Nombre: string;
    DescripcionCorta: string;  
    Seleccionado: boolean;  

    constructor() {
        this.Id = '';
        this.Nombre = '';
        this.DescripcionCorta = '';        
        this.Seleccionado = false;
    }

    public static parseLista(element: any[]): ListadoCategorias[]
    {
      let registros: ListadoCategorias[] = [];
      element.forEach(x => {
        const registro = new ListadoCategorias();
        registro.Id = x.id;
        registro.Nombre = x.nombre;
        registro.DescripcionCorta = x.descripcionCorta;        
        registros.push(registro);
      });
      return registros;
    }
}