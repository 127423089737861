import { RespuestaBaseLista } from "../base/RespuestaBase";
import { RespuestaPaginada } from "../base/respuesta-paginada";

export class ListadoUsuarios {
    
    Id: string;
    NombreUsuario: string;
    Nombres: string;
    Apellidos: string;
    TipoDocumento: string;
    NumeroDocumento: string;
    Correo: string;
    Telefono: string;
    TipoUsuario: string;
    FechaRegistro: Date;
    FechaUltimoAcceso?: Date;
    NombreUsuarioCompleto: string;
    Estado: string;
  Acciones:any;
    constructor() {
        this.Id = '';
        this.Nombres = '';
        this.Apellidos = '';
        this.NumeroDocumento = '';
        this.Correo = '';
        this.Telefono = '';
        this.TipoUsuario = '';
        this.Estado = '';
    }

    public static parseLista(element: any): RespuestaBaseLista<ListadoUsuarios>
    {
      let respuesta = {} as RespuestaBaseLista<ListadoUsuarios>;
      respuesta.data = {} as RespuestaPaginada<ListadoUsuarios>;
      let registros: ListadoUsuarios[] = [];
      element.data.lista.forEach(x => {
        const registro = new ListadoUsuarios();
        registro.Id = x.id;
        registro.NombreUsuario = x.nombreUsuario;
        registro.Nombres = x.nombres;
        registro.Apellidos = x.apellidos;
        registro.TipoDocumento = x.tipoDocumento;
        registro.NumeroDocumento = x.numeroDocumento;
        registro.Correo = x.correo;
        registro.Telefono = x.telefono;
        registro.TipoUsuario = x.tipoUsuario;        
        registro.FechaRegistro = x.fechaRegistro;
        registro.FechaUltimoAcceso = x.fechaUltimoAcceso;
        registro.NombreUsuarioCompleto = x.nombreUsuarioCompleto;
        registro.Estado = x.estado;
        registros.push(registro);
      });      
      respuesta.data.lista = registros;
      respuesta.data.total = element.data.total;
      return respuesta;
    }
}