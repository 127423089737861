import { Injectable } from '@angular/core';
import { stringIsNullOrEmpty } from '@pnp/common';
import { Deferred } from "ts-deferred";
import { TokenService } from '../services/token.service';

export class Funciones {

    constructor(
        private tokenService: TokenService,
    ) {
    }

    public EnviarDatosPost(url: string, datos: any): Promise<any> {
        console.dir(url);
        console.dir(datos);
        const d: Deferred<any> = new Deferred<any>();

        const jsonData = JSON.stringify(datos);
        let error = "";

        try {
            const spRequest = new XMLHttpRequest();
            spRequest.open("POST", url, true);
            spRequest.setRequestHeader("Content-Type", "application/json");
            spRequest.onreadystatechange = () => {
                if (spRequest.readyState === 4 && spRequest.status === 200) {
                    const resultOperacion = JSON.parse(spRequest.responseText);
                    console.dir(resultOperacion);
                    d.resolve(resultOperacion);
                } else if (spRequest.readyState === 4 && spRequest.status !== 200) {
                    console.log("Error ocurrido !");
                    d.reject(spRequest.readyState);
                }
            };
            spRequest.onerror = function () {
                error = "Error en invocación de Servicio: " + url + "- Status: " + this.status + " - StatusText: " + spRequest.statusText + " - Response: " + spRequest.response;
                d.reject(error);
            };
            spRequest.send(jsonData);
        } catch (err) {
            console.dir(err);
        }

        return d.promise;
    }

    public static EnviarDatosGet(url: string): Promise<any> {
        const d: Deferred<any> = new Deferred<any>();

        try {
            const spRequest = new XMLHttpRequest();

            spRequest.open("GET", url, true);
            spRequest.setRequestHeader("Content-Type", "application/json");

            spRequest.onreadystatechange = () => {
                if (spRequest.readyState === 4 && spRequest.status === 200) {
                    const resultOperacion = JSON.parse(spRequest.responseText);
                    d.resolve(resultOperacion);
                } else if (spRequest.readyState === 4 && spRequest.status !== 200) {
                    console.log("Error ocurrido !");
                    d.reject(spRequest.readyState);
                }
            };

            spRequest.send();
        } catch (err) {
            console.dir(err);
        }

        return d.promise;
    }

    static esUndefinedNullOrEmpty(valor: any): boolean {
        if (valor === undefined || valor === null || valor === "") {
            return true;
        }
        return false;
    }

    static ordenarArregloAsc(arreglo: any, propiedad: any): any[] {
        arreglo.sort(function (a: any, b: any) {
            return a[propiedad] > b[propiedad];
        });
        return arreglo;
    }

    static ordenarArregloDesc(arreglo: any, propiedad: any): any[] {
        this.ordenarArregloAsc(arreglo, propiedad);
        arreglo.reverse();
        return arreglo;
    }

    static obtenerCorreos(usuarios: any): string {
        let correos = "";

        for (const usuario of usuarios) {
            if (correos.length > 0) {
                correos += ";";
            }
            correos += usuario.Email;
        }

        return correos;
    }

    static obtenerFechaHoraDocumento(): string {
        const fecha = new Date();

        const fechaHora =
            fecha.getFullYear() +
            "" +
            (fecha.getMonth() + 1).toString().padStart(2, "0") +
            "" +
            fecha.getDate().toString().padStart(2, "0") +
            "_" +
            fecha.getHours().toString().padStart(2, "0") +
            "" +
            fecha.getMinutes().toString().padStart(2, "0") +
            "" +
            fecha.getSeconds().toString().padStart(2, "0");
        /* + "" + fecha.getMilliseconds()*/

        return fechaHora;
    }

    static ConcatenarFechaHoraLLegada(fecha: any, hora: string)
    {
        let resultado = '';
        if(fecha == null) return resultado;
        let fechaTmp = new Date(fecha);
        let horaTmp = !stringIsNullOrEmpty(hora) ? (' ' + this.FormatearHora(hora)) : '';
        return this.ConvertirDateToString(fechaTmp) +  horaTmp;
    }

    static ConcatenarFechaHoraSalida(fecha: any, hora: string)
    {
        let resultado = '';
        if(fecha == null) return resultado;
        let fechaTmp = new Date(fecha);
        fechaTmp.setDate(fechaTmp.getDate() + 1);
        let horaTmp = !stringIsNullOrEmpty(hora) ? (' ' + this.FormatearHora(hora)) : '';
        return this.ConvertirDateToString(fechaTmp) +  horaTmp;
    }

    static ConvertirDateToString(fecha: Date): string {
        let result = "";
        const dia = fecha.getDate() > 9 ? fecha.getDate() : "0" + fecha.getDate();
        const mes =
            fecha.getMonth() + 1 > 9
                ? fecha.getMonth() + 1
                : "0" + (fecha.getMonth() + 1);

        result = [dia, mes, fecha.getFullYear()].join("/");
        return result;
    }

    static ConvertirDateToString2(fecha1: any): string {
        let result = "";
        let fecha = new Date(fecha1);

        const dia = fecha.getDate() > 9 ? fecha.getDate() : "0" + fecha.getDate();
        const mes =
            fecha.getMonth() + 1 > 9
                ? fecha.getMonth() + 1
                : "0" + (fecha.getMonth() + 1);

        let hora = fecha1.split('T')[1];
        hora = hora.substring(0, 5);

        result = [dia, mes, fecha.getFullYear()].join("/") + ' ' + this.FormatearHora(hora);
        return result;
    }

    static MostrarFechaFormatoLetras(valor: any): string {
        let result = "";
        const parts = valor.split("/");

        if (parts.length === 3) {
            const mes = parseInt(parts[1], 10);
            const mesLetras = this.ObtenerMesEnLetras(mes);
            result = parts[0] + " de " + mesLetras + " del " + parts[2];
        }

        return result;
    }

    static ObtenerMesEnLetras(valor: number): string {
        let result = "";
        if (valor === 1) {
            result = "Enero";
        } else if (valor === 2) {
            result = "Febrero";
        } else if (valor === 3) {
            result = "Marzo";
        } else if (valor === 4) {
            result = "Abril";
        } else if (valor === 5) {
            result = "Mayo";
        } else if (valor === 6) {
            result = "Junio";
        } else if (valor === 7) {
            result = "Julio";
        } else if (valor === 8) {
            result = "Agosto";
        } else if (valor === 9) {
            result = "Setiembre";
        } else if (valor === 10) {
            result = "Octubre";
        } else if (valor === 11) {
            result = "Noviembre";
        } else if (valor === 12) {
            result = "Diciembre";
        }

        return result;
    }

    static ObtenerMesAbreviatura(valor: number): string {
        let result = "";
        if (valor === 1) {
            result = "E";
        } else if (valor === 2) {
            result = "F";
        } else if (valor === 3) {
            result = "M";
        } else if (valor === 4) {
            result = "A";
        } else if (valor === 5) {
            result = "M";
        } else if (valor === 6) {
            result = "J";
        } else if (valor === 7) {
            result = "J";
        } else if (valor === 8) {
            result = "A";
        } else if (valor === 9) {
            result = "S";
        } else if (valor === 10) {
            result = "O";
        } else if (valor === 11) {
            result = "N";
        } else if (valor === 12) {
            result = "D";
        }

        return result;
    }

    static MostrarFechaFormatoLetras2(valor: Date): string {
        var dias = new Array(7);
        dias[0] = "Domingo";
        dias[1] = "Lunes";
        dias[2] = "Martes";
        dias[3] = "Miercoles";
        dias[4] = "Jueves";
        dias[5] = "Viernes";
        dias[6] = "Sabado";

        let result = "";
        const mes = valor.getMonth() + 1;
        const dia = valor.getDay();
        const numeroDia = valor.getDate();
        const anio = valor.getFullYear();
        const mesLetras = this.ObtenerMesEnLetras(mes);
        const diaLetras = dias[dia];
        result = diaLetras + ", " + numeroDia + " de " + mesLetras + " del " + anio;

        return result;
    }

    static PadString(pad: any, user_str: any, pad_pos: any) {
        if (typeof user_str === "undefined") return pad;
        if (pad_pos == "l") {
            return (pad + user_str).slice(-pad.length);
        } else {
            return (user_str + pad).substring(0, pad.length);
        }
    }

    static ObtenerObjetoEnListaPorID(Items: any, ID: string): any {
        let objeto = null;
        var ItemsFiltrados = Items.filter((x: any) => x.ID === ID);
        if (ItemsFiltrados.length > 0) {
            objeto = ItemsFiltrados[0];
        }

        return objeto;
    }

    static EsNullVacioUndefined(valor: any): boolean {
        if (valor === undefined || valor === null || valor === "") {
            return true;
        } else {
            return false;
        }
    }

    public static obtenerBlobDesdeBase64(
        fileBase64: string
      ): Blob {
        const sliceSize = 512;
        const byteCharacters = atob(fileBase64);
        const byteArrays: Uint8Array[] = [];
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          const slice = byteCharacters.slice(offset, offset + sliceSize);
          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }
        const blob = new Blob(byteArrays);
        return blob;
    }

    public static esArchivoExtensionValida(extensiones: string[], nombreArchivo: string)
    {
        const extensionArchivo = this.getExtensionArchivo(nombreArchivo);
    
        const esExtensionNoPermitida = (extensiones.filter((extension: string) => {
          return extension.trim().toUpperCase() === extensionArchivo.toUpperCase();
        }));
    
        return esExtensionNoPermitida.length > 0;
    }

    public static getExtensionArchivo(nombreArchivo: string)
    {
        const archivoSplit = nombreArchivo.split('.');
        const extensionArchivo = archivoSplit[archivoSplit.length - 1];    
        return extensionArchivo;
    }

    public static getNombreArchivo(url: string)
    {
        if(this.esUndefinedNullOrEmpty(url))
        {
            return "";
        }
        
        return url.toString().substr(url.toString().lastIndexOf("/") + 1, url.toString().length);
    }

    public static convertirArrayByte(arrayBuffer: any)
    {
        const d: Deferred<any> = new Deferred<any>();
        const reader = new FileReader();
    
        reader.onloadend = function (e) {
          const arrayBuffer = e.target!.result;
          const uint8Array = new Uint8Array(arrayBuffer as ArrayBuffer);
          const bytesArchivo = [].slice.call(uint8Array);
          d.resolve(bytesArchivo);
        };
    
        reader.readAsArrayBuffer(arrayBuffer);
    
        return d.promise;
    };

    public static reemplazarNombreArchivo(nombreArchivo : string): string 
    {
        let fileName = nombreArchivo.slice(0, nombreArchivo.lastIndexOf('.'));
        fileName = this.replaceUnsupportedCharacters(fileName);
        const extension = nombreArchivo.substr(nombreArchivo.lastIndexOf('.') + 1, nombreArchivo.length);
        return fileName + '.' + extension;
    }
    
    private static replaceUnsupportedCharacters(fileName: string): string 
    {
        fileName = fileName.replace("#", "");
        fileName = fileName.replace("%", "");
        fileName = fileName.replace("&", "");
        fileName = fileName.replace("*", "");
        fileName = fileName.replace(":", "");
        fileName = fileName.replace("<", "");
        fileName = fileName.replace(">", "");
        fileName = fileName.replace("?", "");
        fileName = fileName.replace("/", "");
        fileName = fileName.replace("{", "");
        fileName = fileName.replace("|", "");
        fileName = fileName.replace("}", "");
        fileName = fileName.replace("~", "");
        fileName = fileName.replace("\\", "");
        fileName = fileName.replace('"', "");
        fileName = fileName.replace("'", "");
        fileName = fileName.replace("á", "a");
        fileName = fileName.replace("é", "e");
        fileName = fileName.replace("í", "i");
        fileName = fileName.replace("ó", "o");
        fileName = fileName.replace("ú", "u");
        fileName = fileName.replace("Á", "A");
        fileName = fileName.replace("É", "E");
        fileName = fileName.replace("Í", "I");
        fileName = fileName.replace("Ó", "O");
        fileName = fileName.replace("Ú", "U");
        return fileName;
    }

    public static FormatearHora(hora: string)
    {
        if(hora === null) return '';
        const h = parseInt(hora.split(':')[0], 10);
        const m = parseInt(hora.split(':')[1], 10);
        const div = h % 12;
        return (h % 12 ? this.padLeft(div.toString(),'0',2) : 12) + ":" + this.padLeft(m.toString(),'0',2) + ' ' + (h >= 12 ? 'PM' : 'AM');
    }

    static padLeft(text:string, padChar:string, size:number): string {
        return (String(padChar).repeat(size) + text).substr( (size * -1), size) ;
    }

    static FormatearMonto(monto: number)
    {
        return monto.toLocaleString('es-PE', {
            style: 'currency',
            currency: 'PEN',
            minimumFractionDigits: 2,
          });
    }
}
