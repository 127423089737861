import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { I18NEXT_SERVICE, I18NextModule, I18NextLoadResult, ITranslationService, defaultInterpolationFormat } from 'angular-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const i18nextOptions = {
  debug: true,
  fallbackLng: 'es',
  resources: {
    en: {
      translation: {
        "Nuevo_TituloFormulario": "VIRTUAL DOCUMENTS FILING DESK",
        "Nuevo_DatosRemitente": "Sender data",
        "Nuevo_PersonaNatural": "Natural person",
        "Nuevo_PersonaJuridica": "Legal person",
        "Nuevo_TipoDocumento": "Document type",
        "Nuevo_NumeroDocumento": "Document number",
        "Nuevo_Nombres": "First Name",
        "Nuevo_BotonValidar": "Validate",
        "Nuevo_ApellidoPaterno": "Last Name",
        "Nuevo_ApellidoMaterno": "Other Last Name",
        "Nuevo_Pais": "Country",
        "Nuevo_RUC": "RUC",
        "Nuevo_RazonSocial": "Business name",
        "Nuevo_DatosContacto": "Details of the sender's contact person",
        "Nuevo_PersonaContacto": "Sender contact name",
        "Nuevo_CorreoContacto": "Email",
        "Nuevo_TelefonoContacto": "Contact telephone",
        "Nuevo_Destinatario": "Addressee",
        "Nuevo_PersonaDestinataria": "Recipient or contact person",
        "Nuevo_Area": "Area",
        "Nuevo_Sede": "Campus",
        "Nuevo_DescripcionNoAplicaDestinatario": "Select here if it does not apply or you do not know the recipient. A person in charge of the parts table will refer to the corresponding area.",
        "Nuevo_DatosDocumento": "Document data",
        "Nuevo_DescripcionArchivoExtensiones": "Only files with the following extensions are allowed:",
        "Nuevo_DescripcionArchivoPesoMaximo": "The max. size per file is 200 MB",
        "Nuevo_Asunto": "Subject",
        "Nuevo_Referencia": "Reference",
        "Nuevo_BotonAgregarAjduntos": "Add Attachments",
        "Nuevo_Principal": "Principal",
        "Nuevo_Archivo": "Archive",
        "Nuevo_Codigo": "Code",
        "Nuevo_Tipo": "Type",
        "Nuevo_TituloDescripcion": "Title or description",
        "Nuevo_DescripcionNoSeAgregaronAdjuntos": "No attachments have been added.",
        "Nuevo_CheckAutorizacionTratamientoDatos": "I authorize the treatment of my personal data to UNACEM in order to register my request",
        "Nuevo_BotonEnviar": "Send",

        "Exito_Texto1": "The file with number has been sent correctly",
        "Exito_Texto2": "You will receive confirmation of receipt in the email address entered in the registration."
      }
    },
    es: {
      translation: {
        "Nuevo_TituloFormulario": "MESA DE PARTES VIRTUAL",
        "Nuevo_DatosRemitente": "Datos del Remitente",
        "Nuevo_PersonaNatural": "Persona Natural",
        "Nuevo_PersonaJuridica": "Persona Jurídica",
        "Nuevo_TipoDocumento": "Tipo de documento",
        "Nuevo_NumeroDocumento": "Número de documento",
        "Nuevo_Nombres": "Nombres",
        "Nuevo_BotonValidar": "Validar",
        "Nuevo_ApellidoPaterno": "Apellido paterno",
        "Nuevo_ApellidoMaterno": "Apellido materno",
        "Nuevo_Pais": "País",
        "Nuevo_RUC": "RUC",
        "Nuevo_RazonSocial": "Razón social",
        "Nuevo_DatosContacto": "Datos de la persona de contacto del remitente",
        "Nuevo_PersonaContacto": "Nombre del contacto del remitente",
        "Nuevo_CorreoContacto": "Correo electrónico",
        "Nuevo_TelefonoContacto": "Teléfono de contacto",
        "Nuevo_Destinatario": "Destinatario",
        "Nuevo_PersonaDestinataria": "Persona destinataria o de contacto",
        "Nuevo_Area": "Área",
        "Nuevo_Sede": "Sede",
        "Nuevo_DescripcionNoAplicaDestinatario": "Seleccione aquí si no aplica o no conoce al destinatario. Un responsable de mesa de partes derivará al área que corresponda.",
        "Nuevo_DatosDocumento": "Datos del documento",
        "Nuevo_DescripcionArchivoExtensiones": "Sólo se permiten archivos con las siguientes extensiones:",
        "Nuevo_DescripcionArchivoPesoMaximo": "El peso máximo por archivo es de 200 MB",
        "Nuevo_Asunto": "Asunto",
        "Nuevo_Referencia": "Referencia",
        "Nuevo_BotonAgregarAjduntos": "Agregar Adjuntos",
        "Nuevo_Principal": "Principal",
        "Nuevo_Archivo": "Archivo",
        "Nuevo_Codigo": "Código",
        "Nuevo_Tipo": "Tipo",
        "Nuevo_TituloDescripcion": "Título o descripción",
        "Nuevo_DescripcionNoSeAgregaronAdjuntos": "No se han agregado adjuntos.",
        "Nuevo_CheckAutorizacionTratamientoDatos": "Autorizo el tratamiento de mis datos personales a UNACEM con la finalidad que pueda registrar mi solicitud",
        "Nuevo_BotonEnviar": "Enviar",

        "Exito_Texto1": "Se ha enviado correctamente el expediente con número",
        "Exito_Texto2": "Recibirá la confirmación de recepción en el correo electrónico consignado en el registro."
      }
    }
  },
  interpolation: {
    format: I18NextModule.interpolationFormat(defaultInterpolationFormat)
  }
};

export function appInit(i18next: ITranslationService) {
  return () => {
    let promise: Promise<I18NextLoadResult> = i18next
      .use<any>(LanguageDetector)
      .init(i18nextOptions);
    return promise;
  };
}

export function localeIdFactory(i18next: ITranslationService) {
  return i18next.language;
}

export const I18N_PROVIDERS = [
  {
    provide: APP_INITIALIZER,
    useFactory: appInit,
    deps: [I18NEXT_SERVICE],
    multi: true
  },
  {
    provide: LOCALE_ID,
    deps: [I18NEXT_SERVICE],
    useFactory: localeIdFactory
  },
];


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    I18NextModule.forRoot()
  ],
  providers: [
    I18N_PROVIDERS
  ]
})
export class AppTranslationModule { }

