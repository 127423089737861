import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WebLayoutComponent } from './shared/layouts/web-layout/web-layout.component';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { AdminLayoutComponent } from './shared/layouts/admin-layout/admin-layout.component';
import { ActivarUsuarioComponent } from './components/reservas/externa/activar-usuario/activar-usuario.component';
import { ResetearContrasenaComponent } from './components/reservas/externa/login/resetear-contrasena/resetear-contrasena.component';
import { NuevoParticipanteComponent } from './components/reservas/externa/nuevo-participante/nuevo-participante.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [{
      path: 'admin',
      loadChildren: () => import('./shared/layouts/admin-layout/admin-layout.module').then(m => m.AdminLayoutModule)
    }]
  },
  {
    path: '',
    component: WebLayoutComponent,
    children: [{
      path: '',
      loadChildren: () => import('./shared/layouts/web-layout/web-layout.module').then(m => m.WebLayoutModule)
    }]
  },
  {
    path: 'actived',
    component: ActivarUsuarioComponent,
  },
  {
    path: 'resetPassword',
    component: ResetearContrasenaComponent,
  },
  { path: 'nuevo-participante', 
    component: NuevoParticipanteComponent 
  }
  /*{
    path: 'bandeja-eventos',
    component: BandejaEventosComponent
  },  
  
  {
    path: 'bandeja-perfiles',
    component: BandejaPerfilesComponent
  },
  {
    path: 'listado-eventos',
    component: ListadoEventosComponent
  },
  {
    path: 'detalle-evento',
    component: DetalleEventoComponent
  },
  {
    path: 'servicios-evento',
    component: ServiciosEventoComponent
  },
  {
    path: 'pagar-servicio',
    component: PagarServicioComponent
  },
  {
    path: 'listado-favoritos',
    component: ListadoFavoritosComponent
  },
  {
    path: 'listado-reservas',
    component: ListadoReservasComponent
  },
  {
    path: 'perfil-usuario',
    component: PerfilUsuarioComponent
  },
  {
    path: 'bandeja-reservas',
    component: BandejaReservasComponent
  },
  {
    path: 'diseno-listado-eventos',
    component: DisenoListadoEventosComponent
  },
  {
    path: 'inicio-sesion',
    component: InicioSesionComponent
  },
  {
    path: 'registro-usuario',
    component: RegistroUsuarioComponent
  },
  {
    path: 'recuperar-contrasena',
    component: RecuperarContrasenaComponent
  },
  {
    path: 'resetear-contrasena',
    component: ResetearContrasenaComponent
  }*/

]

/*@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})*/
@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
       useHash: false
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
