<div class="nav-bar-black">
    <div>
        <a icon-button routerLink="/home">
            <img src="assets/img/logo-aire.png" height="56px" alt="">
        </a>
    </div>
</div>

<div class="container" style="margin-top: 5rem;">
    <mat-card class="col-lg-6 border-custom" style="margin: auto;"  *ngIf="!form">
        <mat-card-content style="text-align: center;">
            <h4>Url invalido</h4>

            <p style="
            font-family: revert;
            font-size: 100px;
            font-weight: 100;
            margin-top: 4rem;">404</p>
            <p style="
            font-size: 2rem;
            font-weight: 100;
            margin-bottom: 7rem;">Page Not Found</p>
        </mat-card-content>
    </mat-card>
    <mat-card class="col-lg-4 col-md-8 border-custom" style="margin: auto;"  *ngIf="form">
        <!-- <mat-card-header>
            <mat-card-title>Activacion de usuario</mat-card-title>
        </mat-card-header> -->
        <mat-card-content>

            <h2 mat-dialog-title>Cambiar Contraseña</h2>
            <form [formGroup]="form" style="margin-top: 2rem; margin-bottom: 2rem;">
                <div class="row">
                    <div class="col-md-12">
                      <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Contraseña Nueva</mat-label>
                          <input matInput autocomplete="off" maxlength="20" placeholder="Contraseña Nueva" formControlName="contrasenia" [type]="hideNueva ? 'password' : 'text'" >
                          <mat-icon matSuffix (click)="hideNueva = !hideNueva">{{hideNueva ? 'visibility_off' : 'visibility'}}</mat-icon>
                          <mat-error *ngIf="form.get('contrasenia')?.errors?.required">
                            Campo obligatorio.
                          </mat-error>                          
                        </mat-form-field>
                    </div>                                
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Repetir Contraseña Nueva</mat-label>
                          <input matInput autocomplete="off" maxlength="20" placeholder="Repetir Contraseña Nueva" formControlName="contraseniaRepetir" [type]="hideNuevaR ? 'password' : 'text'" >
                          <mat-icon matSuffix (click)="hideNuevaR = !hideNuevaR">{{hideNuevaR ? 'visibility_off' : 'visibility'}}</mat-icon>
                          <mat-error *ngIf="form.get('contraseniaRepetir')?.errors?.required">
                            Campo obligatorio.
                          </mat-error>
                        </mat-form-field>
                    </div>                                
                </div>
                <button type="button" mat-raised-button class="btn btn-rose pull-right"  (click)="onSave()">Guardar</button>
                <!-- <button type="button" mat-raised-button class="btn btn-rose pull-right ml-5" (click)="onCancelar()">Cancelar</button> -->
                <div class="clearfix"></div>
            
                <mat-error *ngIf="form.hasError('UpperCase', 'Contrasenia')">
                  Debe contener carácter en mayúscula
                </mat-error>
                <mat-error *ngIf="form.hasError('Numeric', 'Contrasenia')">
                  Debe contener números
                </mat-error>
                <mat-error *ngIf="form.hasError('SpecialCase', 'Contrasenia')">
                  Debe contener carácter especial
                </mat-error>
                <mat-error *ngIf="form.controls.contrasenia.errors?.minlength" >Mínimo {{form.controls.contrasenia.errors?.minlength.requiredLength}} caracteres</mat-error>
                <mat-error *ngIf="form.hasError('notSame') && form.controls.contraseniaRepetir.value">
                  Contraseña ingresadas no son iguales
                </mat-error>
            
              </form>
        </mat-card-content>
    </mat-card>
</div>

