import { Injectable } from '@angular/core';
import { AccesoUsuario } from '../models/response/acceso-usuario';
import { ListadoOpciones } from '../models/response/listado-opciones';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  private userStorageKey: string = 'SesionUsuario';
  private optionStorageKey: string = 'OpcionesMenu';

  constructor() { }

  get Usuario(): AccesoUsuario {
    const valorUsuario = localStorage.getItem(this.userStorageKey);
    if (!valorUsuario) {
      return null as any;
    }
    const usuario = JSON.parse(atob(valorUsuario));
    return usuario as AccesoUsuario;
  }

  set Usuario(item: AccesoUsuario) 
  {
    const stringValue = btoa(JSON.stringify(item));
    localStorage.setItem(this.userStorageKey, stringValue);
  }

  set Opciones(item: ListadoOpciones[]) 
  {
    const stringValue = btoa(JSON.stringify(item));
    localStorage.setItem(this.optionStorageKey, stringValue);
  }

  get Opciones(): ListadoOpciones[] {
    const valorUsuario = localStorage.getItem(this.optionStorageKey);
    if (!valorUsuario) {
      return null as any;
    }
    const usuario = JSON.parse(atob(valorUsuario));
    return usuario as ListadoOpciones[];
  }

  public CerrarSession()
  {
    localStorage.removeItem(this.userStorageKey);
  }
}
