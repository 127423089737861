<div class="nav-bar-black">
    <div>
        <a icon-button routerLink="/home">
            <!--<img src="assets/inn/logo.png" height="56px" alt="">-->
            <img src="assets/img/logo-aire.png" height="56px" alt="">
        </a>
    </div>
</div>
<div class="container" style="margin-top: 5rem; ">
    <mat-card style="padding-top: 5rem; padding-bottom: 5rem;" class="border-custom" *ngIf="esperandoConfirmacion">
        <mat-card-content style="text-align: center;">
            <p  style="color: #a8a941;">Validando confirmación de usuario registrado.</p>
        </mat-card-content>        
    </mat-card>
    <mat-card style="padding-top: 5rem; padding-bottom: 5rem;" class="border-custom" *ngIf="!esperandoConfirmacion && disabledButtonLogin">
        <mat-card-content style="text-align: center;">            
            <mat-icon matSuffix style="color: #9e2a13;font-size: 94px;height: 121px;width: 125px;">
                highlight_off
            </mat-icon>
            <p  style="color: #9e2a13;">URL inactivo</p>
        </mat-card-content>
        <mat-card-actions style="text-align: center;">
            <button mat-stroked-button class="btn-stroked-perfil mr-8 btn-primario" routerLink="/inicio-sesion" [disabled]="disabledButtonLogin">Ir a Login  <mat-icon>account_circle</mat-icon></button> 
        </mat-card-actions>
    </mat-card>
    <mat-card style="padding-top: 5rem; padding-bottom: 5rem;" class="border-custom" *ngIf="!esperandoConfirmacion && !disabledButtonLogin">
        <mat-card-content style="text-align: center;">            
            <mat-icon matSuffix style="color: #a8a941;font-size: 94px;height: 121px;width: 125px;">
                check_circle_outline
            </mat-icon>
            <p  style="color: #a8a941;">Usuario Activado</p>            
        </mat-card-content>
        <mat-card-actions style="text-align: center;">
            <button mat-stroked-button class="btn-stroked-perfil mr-8 btn-primario" routerLink="/inicio-sesion" [disabled]="disabledButtonLogin">Ir a Login  <mat-icon>account_circle</mat-icon></button> 
        </mat-card-actions>
    </mat-card>
</div>