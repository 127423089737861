import { RespuestaBaseLista } from "../base/RespuestaBase";
import { RespuestaPaginada } from "../base/respuesta-paginada";

export class ListadoEventos {
    
    Id: string;
    Nombre: string;
    Descripcion: string;
    Direccion: string;
    FechaRegistro: Date;
    FechaVigencia?: Date;
    Estado: string;
    UsuarioRegistro: string;
    CantidadRegistrados: number;
    CantidadVerificados: number;

    constructor() {
        this.Id = '';
        this.Nombre = '';
        this.Descripcion = '';
        this.Direccion = '';
        this.CantidadRegistrados = 0;
        this.CantidadVerificados = 0;
    }

    public static parseLista(element: any): RespuestaBaseLista<ListadoEventos>
    {
      let respuesta = {} as RespuestaBaseLista<ListadoEventos>;
      respuesta.data = {} as RespuestaPaginada<ListadoEventos>;
      let eventos: ListadoEventos[] = [];
      element.data.lista.forEach(x => {
        const evento = new ListadoEventos();
        evento.Id = x.id;
        evento.Nombre = x.nombre;
        evento.Descripcion = x.descripcion;
        evento.Direccion = x.direccion;
        evento.FechaRegistro = x.fechaRegistro;
        evento.FechaVigencia = x.fechaFin;
        evento.CantidadRegistrados = x.cantidadRegistrados;
        evento.CantidadVerificados = x.cantidadVerificados;
        evento.Estado = x.estado;
        evento.UsuarioRegistro = x.usuarioRegistro;
        eventos.push(evento);
      });      
      respuesta.data.lista = eventos;
      respuesta.data.total = element.data.total;
      return respuesta;
    }
}