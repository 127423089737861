import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { HttpRequestInterceptor } from './shared/interceptors/HttpRequestInterceptor';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { AppRoutingModule } from './app-routing.module';
import { AppTranslationModule } from './app-translation.module';
import { AppComponent } from './app.component';
import { ModalDialog } from "./shared/components/modal/modal.component";
import { NgxSpinnerModule } from 'ngx-spinner';
import es from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { EnviarComunicadoComponent } from './components/reservas/modals/enviar-comunicado/enviar-comunicado.component';
import { TinyEditorComunicadoComponent } from './components/reservas/modals/tiny-editor-comunicado/tiny-editor-comunicado.component';
import { WebLayoutComponent } from './shared/layouts/web-layout/web-layout.component';
import { AdminLayoutComponent } from './shared/layouts/admin-layout/admin-layout.component';
import { RouterModule } from '@angular/router';
import { InternaModule } from './components/reservas/interna/interna.module';
import { ExternaModule } from './components/reservas/externa/externa.module';
import { ComunModule } from './shared/comun.module';
import { ReprogramarReservaComponent } from './components/reservas/modals/reprogramar-reserva/reprogramar-reserva.component';
import { AnularReservaComponent } from './components/reservas/modals/anular-reserva/anular-reserva.component';
import { VerificarLoginComponent } from './components/reservas/modals/verificar-login/verificar-login.component';


registerLocaleData(es);

@NgModule({
  imports: [    
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule, 
    ComunModule,
    InternaModule,
    ExternaModule,
    RouterModule,
    AppRoutingModule,    
    AppTranslationModule,
    NgxSpinnerModule,    
    ToastrModule.forRoot()
  ],
  declarations: [
    AppComponent,
    ModalDialog,    
    EnviarComunicadoComponent,
    TinyEditorComunicadoComponent,    
    WebLayoutComponent,
    AdminLayoutComponent,
    ReprogramarReservaComponent,
    AnularReservaComponent,
    VerificarLoginComponent        
  ],  
  exports: [    
    HttpClientModule,
    ModalDialog,
    NgxSpinnerModule,
    TinyEditorComunicadoComponent,
    ReprogramarReservaComponent,
    AnularReservaComponent,
    VerificarLoginComponent
  ],
  entryComponents:[
    ModalDialog
  ],
  providers: [ {
    provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true
  },{
    provide: MAT_DATE_LOCALE, useValue: 'es-GB'
  },
  DatePipe
],
  bootstrap: [AppComponent]
})
export class AppModule {   
}
