import { RespuestaPaginada } from "../base/respuesta-paginada";
import { RespuestaBaseLista } from "../base/RespuestaBase";

export class ListadoBanners {
    
    Id: string;
    Nombre: string;
    Tipo: string;
    UrlEnlaceExterno: string;
    Descripcion: string;
    UrlImagen: string;
    Enlace: string;
    Estado: string;

    constructor() {
        this.Id = '';
        this.Nombre = '';
        this.Descripcion = '';
        this.UrlImagen = '';
        this.Estado = '';
    }

    public static parseLista(element: any[]): ListadoBanners[]
    {
      let registros: ListadoBanners[] = [];
      element.forEach(x => {
        const registro = new ListadoBanners();
        registro.Id = x.id;
        registro.Nombre = x.nombre;
        registro.Descripcion = x.descripcion;
        registro.UrlImagen = x.urlImagen;
        registro.Tipo = x.tipo;
        registro.UrlEnlaceExterno = x.urlEnlaceExterno;
        registro.Enlace = x.enlace;
        registros.push(registro);
      });
      return registros;
    }

    public static parseListaBandeja(element: any): RespuestaBaseLista<ListadoBanners>
    {
      let respuesta = {} as RespuestaBaseLista<ListadoBanners>;
      respuesta.data = {} as RespuestaPaginada<ListadoBanners>;
      let registros: ListadoBanners[] = [];
      element.data.lista.forEach(x => {
        const registro = new ListadoBanners();
        registro.Id = x.id;
        registro.Nombre = x.nombre;
        registro.Tipo = x.tipo;
        registro.UrlImagen = x.urlImagen;
        registro.Estado = x.estado;
        registros.push(registro);
      });      
      respuesta.data.lista = registros;
      respuesta.data.total = element.data.total;
      return respuesta;
    }
}