import { RespuestaBaseLista } from "../base/RespuestaBase";
import { RespuestaPaginada } from "../base/respuesta-paginada";

export class ListadoTipoServicios {
    
    Id: string;
    Nombre: string;

    constructor() {
        this.Id = '';
        this.Nombre = '';
    }

    public static parseLista(element: any[]): ListadoTipoServicios[]
    {
      let tipoServicios: ListadoTipoServicios[] = [];
      element.forEach(x => {
        const evento = new ListadoTipoServicios();
        evento.Id = x.id;
        evento.Nombre = x.nombre;
        tipoServicios.push(evento);
      });      
      return tipoServicios;
    }
}