export class ListadoProvincias {
    
    Id: string;
    Nombre: string;

    constructor() {
        this.Id = '';
        this.Nombre = '';
    }

    public static parseLista(element: any[]): ListadoProvincias[]
    {
      let registros: ListadoProvincias[] = [];
      element.forEach(x => {
        const registro = new ListadoProvincias();
        registro.Id = x.id;
        registro.Nombre = x.nombre;
        registros.push(registro);
      });
      return registros;
    }
}