import { NgModule } from '@angular/core';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DetalleEventoComponent } from './detalle-evento/detalle-evento.component';
import { ListadoFavoritosComponent } from './listado-favoritos/listado-favoritos.component';
import { ListadoReservasComponent } from './listado-reservas/listado-reservas.component';
import { InicioSesionComponent } from './login/inicio-sesion/inicio-sesion.component';
import { RecuperarContrasenaComponent } from './login/recuperar-contrasena/recuperar-contrasena.component';
import { RegistroUsuarioComponent } from './login/registro-usuario/registro-usuario.component';
import { ResetearContrasenaComponent } from './login/resetear-contrasena/resetear-contrasena.component';
import { NuevoParticipanteComponent } from './nuevo-participante/nuevo-participante.component';
import { PagarServicioComponent } from './pagar-servicio/pagar-servicio.component';
import { PerfilUsuarioComponent } from './perfil-usuario/perfil-usuario.component';
import { DisenoListadoEventosComponent } from '../diseno/diseno-listado-eventos/diseno-listado-eventos.component';
import { ComunModule } from 'src/app/shared/comun.module';
import { GoogleMapsModule } from '@angular/google-maps'
import { ActivarUsuarioComponent } from './activar-usuario/activar-usuario.component';
import { CambiarContraseniaComponent } from './cambiar-contrasenia/cambiar-contrasenia.component';
import { ConfirmacionPagoComponent } from '../modals/confirmacion-pago/confirmacion-pago.component';
import { HomeComponent } from './home/home.component';
import { DetalleBannerComponent } from './detalle-banner/detalle-banner.component';
import { PoliticasComponent } from './politicas/politicas.component';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { ContactoComponent } from './contacto/contacto.component';
import { ListadoPagosComponent } from './listado-pagos/listado-pagos.component';
import { RealizarPagoComponent } from './realizar-pago/realizar-pago.component';

@NgModule({
  imports: [
    CommonModule,
    ComunModule,
    RouterModule,
    GoogleMapsModule,
    PdfJsViewerModule
  ],
  declarations: [
    HomeComponent,
    DetalleEventoComponent,
    ListadoFavoritosComponent,
    ListadoReservasComponent,
    InicioSesionComponent,
    RecuperarContrasenaComponent,
    RegistroUsuarioComponent,
    ResetearContrasenaComponent,
    NuevoParticipanteComponent,
    PagarServicioComponent,
    PerfilUsuarioComponent,
    DisenoListadoEventosComponent,
    ActivarUsuarioComponent,
    CambiarContraseniaComponent,
    ConfirmacionPagoComponent,
    DetalleBannerComponent,
    PoliticasComponent,
    ContactoComponent,
    ListadoPagosComponent,
    RealizarPagoComponent
  ],
  entryComponents: [
    CambiarContraseniaComponent
  ],
  exports: [
    HomeComponent,
    DetalleEventoComponent,
    ListadoFavoritosComponent,
    ListadoReservasComponent,
    InicioSesionComponent,
    RecuperarContrasenaComponent,
    RegistroUsuarioComponent,
    ResetearContrasenaComponent,
    NuevoParticipanteComponent,
    PagarServicioComponent,
    PerfilUsuarioComponent,
    DisenoListadoEventosComponent,
    ConfirmacionPagoComponent    
  ],
  providers: [
    DatePipe
  ],
})
export class ExternaModule { }