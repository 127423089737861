import { RespuestaBaseLista } from "../base/RespuestaBase";
import { RespuestaPaginada } from "../base/respuesta-paginada";

export class ListadoOpciones {
    
    OpcionId: string;
    Nombre: string;
    Ruta: string;
    Icono: string;
    EsOpcionPadre: boolean;
    OpcionPadreId: string;
    VisibleMenu: boolean;
    Colapsado: boolean;
    Opciones: any[];
  
    constructor() {
        this.OpcionId = '';
        this.Nombre = '';
        this.Ruta = '';
        this.Icono = '';
        this.EsOpcionPadre = false;
        this.OpcionPadreId = '';
        this.VisibleMenu = false;
        this.Colapsado = false;
        this.Opciones = [];
    }

    public static parseLista(element: any): RespuestaBaseLista<ListadoOpciones>
    {
      let respuesta = {} as RespuestaBaseLista<ListadoOpciones>;
      respuesta.data = {} as RespuestaPaginada<ListadoOpciones>;
      let registros: ListadoOpciones[] = [];
      element.data.forEach(x => {
        const registro = new ListadoOpciones();
        registro.OpcionId = x.opcionId;
        registro.Nombre = x.nombre;
        registro.Ruta = x.ruta;
        registro.Icono = x.icono;
        registro.VisibleMenu = x.visibleMenu;
        registro.EsOpcionPadre = x.esOpcionPadre;
        registro.Colapsado = false;

        let opciones = [];

        if(x.opciones !== null)
        {
          x.opciones.forEach(y => 
            {
                const opcion : any = 
                {
                    OpcionId: y.opcionId,
                    Nombre: y.nombre,
                    Ruta: y.ruta,
                    Icono: y.icono,
                    VisibleMenu: y.visibleMenu,
                    Colapsado: false
                };
                opciones.push(opcion);
            });
        }        

        registro.Opciones = opciones;

        registros.push(registro);
      });      
      respuesta.data.lista = registros;
      return respuesta;
    }
}