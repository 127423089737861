export class ListadoDistritos {
    
    Id: string;
    Nombre: string;
    ProvinciaId: string;

    constructor() {
        this.Id = '';
        this.Nombre = '';
        this.ProvinciaId = '';
    }

    public static parseLista(element: any[]): ListadoDistritos[]
    {
      let registros: ListadoDistritos[] = [];
      element.forEach(x => {
        const registro = new ListadoDistritos();
        registro.Id = x.id;
        registro.Nombre = x.nombre;
        registro.ProvinciaId = x.provinciaId;
        registros.push(registro);
      });
      return registros;
    }
}