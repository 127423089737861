import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ClienteApiHttpService } from './cliente-api-http.service';
import { Router, UrlSerializer } from '@angular/router';
import { RespuestaBase, RespuestaBaseDetalle, RespuestaBaseLista } from '../models/base/RespuestaBase';
import { HttpClient } from '@angular/common/http';
import { ListadoUsuarios } from '../models/response/listado-usuarios';
import { AccesoUsuario } from '../models/response/acceso-usuario';
import { RespuestaExitosa } from '../models/base/RespuestaExitosa';
import { ListadoOpciones } from '../models/response/listado-opciones';
import { Funciones } from '../utils/Funciones';

@Injectable({ providedIn: 'root' })
export class UsuariosService {
  
  private uriObtenerUsuariosBandeja: string = environment.apiUrlBackEnd + '/api/usuario/listar-usuarios-bandeja';
  private uriRegistrarEnvioComunicado: string = environment.apiUrlBackEnd + '/api/usuario/registrar-envio-comunicado';
  private uriIniciarSesion: string = environment.apiUrlBackEnd + '/api/usuario/iniciar-sesion';
  private uriRegistroUsuario: string = environment.apiUrlBackEnd + '/api/usuario/registrar-usuario';
  private uriModificaUsuario: string = environment.apiUrlBackEnd + '/api/usuario/Modificar-usuario';
  private uriActivarUsuario: string = environment.apiUrlBackEnd + '/api/usuario/activar-usuario';
  private uriInitUsuario: string = environment.apiUrlBackEnd + '/api/usuario/init-usuario';
  private uriRecuperarContrasenia: string = environment.apiUrlBackEnd + '/api/usuario/recuperar-contrasenia';
  private uriCambiarContrasenia: string  = environment.apiUrlBackEnd + '/api/usuario/cambiar-contrasenia';
  private uriResetearContraseniaInit: string = environment.apiUrlBackEnd + '/api/usuario/resetear-contrasenia-init';
  private uriResetearContrasenia: string = environment.apiUrlBackEnd + '/api/usuario/resetear-contrasenia';
  private uriFiltrarUsuariosBandeja: string = environment.apiUrlBackEnd + '/api/usuario/filtrar-usuarios';
  private uriObtenerOpcionesPorUsuario: string = environment.apiUrlBackEnd + '/api/usuario/obtener-opciones-usuario';
  private uriRegistroUsuarioInterno: string = environment.apiUrlBackEnd + '/api/usuario/registrar-usuario-interno';
  private uriModificaUsuarioInterno: string = environment.apiUrlBackEnd + '/api/usuario/Modificar-usuario-interno';
  private uriEliminarUsuario: string = environment.apiUrlBackEnd + '/api/usuario/eliminar-usuario';
  private uriExportarUsuario: string = environment.apiUrlBackEnd + '/api/usuario/exportar-usuario';

  constructor(
    private clienteApiHtppService: ClienteApiHttpService,
    private httpClient: HttpClient,
    private router: Router,
    private serializer: UrlSerializer) {
    /*sp.setup({
      sp: {
        baseUrl: `${environment.proxyUrl}`
      }
    });*/
  }

  public obtenerUsuarios(body: any): Promise<RespuestaBaseLista<ListadoUsuarios>> {
    return new Promise<RespuestaBaseLista<ListadoUsuarios>>((resolve, reject) => {
      this.clienteApiHtppService.post(this.uriObtenerUsuariosBandeja, body).subscribe(
        res => {
          resolve(ListadoUsuarios.parseLista(res))
        },
        (error: any) => reject(error)
      );
    });
  }

  public registrarEnvioComunicado(body: any): Promise<RespuestaBaseDetalle<boolean>> {
    
    return new Promise<RespuestaBaseDetalle<boolean>>(resolve => {
      this.httpClient.post<RespuestaBaseDetalle<boolean>>(this.uriRegistrarEnvioComunicado, body).subscribe(res => {
        resolve(res);
      });
    });
  }

  public iniciarSesion(nombreUsuario: string, contrasenia: string): Promise<RespuestaBaseDetalle<AccesoUsuario>> {

    const url = `${this.uriIniciarSesion}?nombreUsuario=${nombreUsuario}&contrasenia=${contrasenia}`;
    return new Promise<RespuestaBaseDetalle<AccesoUsuario>>(resolve => {
      this.httpClient.get<RespuestaBaseDetalle<AccesoUsuario>>(url).subscribe(res => {
        resolve(res);
      });
    });
  }
  
  public registrarUsuario(body: any): Promise<RespuestaExitosa<any>>
  {
    return new Promise<RespuestaExitosa<any>>(resolve=>{
      this.httpClient.post<RespuestaExitosa<any>>(this.uriRegistroUsuario, body).subscribe(res => {
        resolve(res);
      });
    });
  }

  public modificarUsuario(body: any): Promise<RespuestaBase<any>>
  {
    return new Promise<RespuestaBase<any>>(resolve=>{
      this.httpClient.post<RespuestaBase<any>>(this.uriModificaUsuario, body).subscribe(res => {
        resolve(res);
      });
    });
  }

  public InitUsuario(id:any): Promise<RespuestaExitosa<any>>
  {
    const url = `${this.uriInitUsuario}?id=${id}`;
    return new Promise<RespuestaBaseDetalle<AccesoUsuario>>(resolve => {
      this.httpClient.get<RespuestaBaseDetalle<AccesoUsuario>>(url).subscribe(res => {
        resolve(res);
      });
    });
  }

  public activarUsuario(key: any): Promise<RespuestaExitosa<any>>
  {
    const url = `${this.uriActivarUsuario}?key=${key}`;

    return new Promise<RespuestaExitosa<any>>(resolve =>{
      this.httpClient.get<RespuestaExitosa<any>>(url).subscribe(res => {
        resolve(res);
      });
    });

  }

  public cambiarContrasenia(body: any): Promise<RespuestaBase<any>>
  {
    return new Promise<RespuestaBase<any>>(resolve=>{
      this.httpClient.post<RespuestaBase<any>>(this.uriCambiarContrasenia, body).subscribe(res => {
        resolve(res);
      });
    });
  }

  public recuperarContrasenia(body: any): Promise<RespuestaBase<any>>
  {
    return new Promise<RespuestaBase<any>>(resolve=>{
      this.httpClient.post<RespuestaBase<any>>(this.uriRecuperarContrasenia, body).subscribe(res => {
        resolve(res);
      });
    });
  }

  public resetearContraseniaInit(body: any): Promise<RespuestaExitosa<any>>
  {
    return new Promise<RespuestaExitosa<any>>(resolve=>{
      this.httpClient.post<RespuestaExitosa<any>>(this.uriResetearContraseniaInit, body).subscribe(res => {
        resolve(res);
      });
    });
  }

  public ResetearContrasenia(body: any): Promise<RespuestaExitosa<any>>
  {
    return new Promise<RespuestaExitosa<any>>(resolve=>{
      this.httpClient.post<RespuestaExitosa<any>>(this.uriResetearContrasenia, body).subscribe(res => {
        resolve(res);
      });
    });
  }

  public filtrarUsuarios(body: any): Promise<RespuestaBaseLista<ListadoUsuarios>> {
    return new Promise<RespuestaBaseLista<ListadoUsuarios>>((resolve, reject) => {
      this.clienteApiHtppService.post(this.uriFiltrarUsuariosBandeja, body).subscribe(
        res => {
          resolve(ListadoUsuarios.parseLista(res))
        },
        (error: any) => reject(error)
      );
    });
  }

  public obtenerOpcionesPorUsuario(idUsuario: string): Promise<RespuestaBaseLista<ListadoOpciones>> {

    const url = `${this.uriObtenerOpcionesPorUsuario}?idUsuario=${idUsuario}`;
    return new Promise<RespuestaBaseLista<ListadoOpciones>>(resolve => {
      this.httpClient.get<RespuestaBaseLista<ListadoOpciones>>(url).subscribe(res => {
        resolve(ListadoOpciones.parseLista(res));
      });
    });
  }

  public registrarUsuarioInterno(body: any): Promise<RespuestaExitosa<any>>
  {
    return new Promise<RespuestaExitosa<any>>(resolve=>{
      this.httpClient.post<RespuestaExitosa<any>>(this.uriRegistroUsuarioInterno, body).subscribe(res => {
        resolve(res);
      });
    });
  }

  public modificarUsuarioInterno(body: any): Promise<RespuestaBase<any>>
  {
    return new Promise<RespuestaBase<any>>(resolve=>{
      this.httpClient.post<RespuestaBase<any>>(this.uriModificaUsuarioInterno, body).subscribe(res => {
        resolve(res);
      });
    });
  }

  public eliminarUsuario(idUsuario: string): Promise<boolean> {

    const url = `${this.uriEliminarUsuario}?idUsuario=${idUsuario}`;

    return new Promise<any>((resolve, reject) => {
        this.clienteApiHtppService.post(url, null).subscribe(
        (res: any) => {
            resolve(res)
        },
        (error: any) => reject(error)
        );
    });
  }
  
  public exportarUsuario(body: any, nombreArchivo: string): Promise<string>
  {
    return new Promise<string>((resolve, reject) => {      
      this.httpClient.post(this.uriExportarUsuario, body).subscribe(
        (resultado: any) => {
          const blob = Funciones.obtenerBlobDesdeBase64(resultado.data);            
          const url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          a.download = nombreArchivo;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
          resolve(nombreArchivo);
        },
        (error: any) => reject(error)
      );
    });
  }

}
