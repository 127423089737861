import { Component, OnInit } from '@angular/core';
import { AccesoUsuario } from '../../models/response/acceso-usuario';
import { ListadoOpciones } from '../../models/response/listado-opciones';
import { SessionService } from '../../services/session.service';
import { UsuariosService } from '../../services/usuarios.service';

declare const $: any;
declare interface RouteInfo {
    id: number;
    path: string;
    title: string;
    icon: string;
    class: string;
    tieneHijos: boolean;
    subMenu: RouteInfo[];
    visible: boolean;
    oculto: boolean;
}
/*export const ROUTES: RouteInfo[] = [
    { id: 1, path: '/admin/perfil-usuario', title: 'Perfil',  icon: 'person', class: '', tieneHijos: true, visible: false, subMenu: [] },  
    
    /*{ id: 2, path: '/admin/perfil-usuario', title: 'Usuario',  icon: 'person', class: '', tieneHijos: false, visible: false },
    { id: 3, path: '/admin/perfil-usuario', title: 'Membresias',  icon: 'person', class: '', tieneHijos: false, visible: false },
    { id: 4, path: '/admin/perfil-usuario', title: 'Promociones',  icon: 'person', class: '', tieneHijos: false, visible: false },
    { id: 5, path: '/admin/perfil-usuario', title: 'Pagos',  icon: 'person', class: '', tieneHijos: false, visible: false },
    { id: 6, path: '/admin/perfil-usuario', title: 'Favoritos',  icon: 'person', class: '', tieneHijos: false, visible: false },

    { id: 2, path: '/admin/eventos', title: 'Gestión Eventos',  icon: 'music_note', class: '', tieneHijos: false, visible: false, subMenu: [] },
    { id: 3, path: '/admin/perfiles', title: 'Gestión Perfiles',  icon:'people', class: '', tieneHijos: false, visible: false, subMenu: [] },
    { id: 4, path: '/admin/reservas', title: 'Gestión Reservas',  icon:'insert_invitation', class: '', tieneHijos: false, visible: false, subMenu: [] },
    { id: 5, path: '/admin/incidentes', title: 'Gestión Incidentes',  icon:'fireplace', class: '', tieneHijos: false, visible: false, subMenu: [] },
    { id: 6, path: '/admin/pendientes', title: 'Gestión Pendientes',  icon:'format_list_numbered', class: '', tieneHijos: false, visible: false, subMenu: [] },
    { id: 7, path: '/admin/reportes', title: 'Reportes',  icon:'dashboard', class: '', tieneHijos: true, visible: false, subMenu: [] },
    
];*/

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  menuItems: any[] = [];

  constructor(
    public usuariosService: UsuariosService,
    private sessionService: SessionService
  )
  {
  }

  ngOnInit() {

    this.setearOpcionesMenu();

    //this.menuItems = ROUTES.filter(menuItem => menuItem);
  }

  async setearOpcionesMenu()
  {
    /*const opciones : any = [
      { id: 1, path: '/admin/perfil-usuario', title: 'Perfil',  icon: 'person', class: '', tieneHijos: true, visible: false, subMenu: [
        { id: 1, path: '/admin/usuario', title: 'Usuario',  icon: 'person', class: '', tieneHijos: false, visible: false, subMenu: [] },
        { id: 2, path: '/admin/listado-reservas', title: 'Reservas',  icon: 'insert_invitation', class: '', tieneHijos: false, visible: false, subMenu: [] },
        { id: 3, path: '/admin/membresias', title: 'Membresias',  icon: 'card_membership', class: '', tieneHijos: false, visible: false, subMenu: [] },
        { id: 4, path: '/admin/promociones', title: 'Promociones',  icon: 'sailing', class: '', tieneHijos: false, visible: false, subMenu: [] },
        { id: 5, path: '/admin/pagos', title: 'Pagos',  icon: 'payment', class: '', tieneHijos: false, visible: false, subMenu: [] },
        //{ id: 5, path: '/admin/favoritos', title: 'Favoritos',  icon: 'favorite_border', class: '', tieneHijos: false, visible: false, subMenu: [] }
      ], oculto: false },      
      { id: 2, path: '/admin/eventos', title: 'Gestión Eventos',  icon: 'music_note', class: '', tieneHijos: false, visible: false, subMenu: [], oculto: false },
      { id: 3, path: '/admin/perfiles', title: 'Gestión Perfiles',  icon:'people', class: '', tieneHijos: false, visible: false, subMenu: [], oculto: false },
      { id: 4, path: '/admin/reservas', title: 'Gestión Reservas',  icon:'insert_invitation', class: '', tieneHijos: false, visible: false, subMenu: [], oculto: false },
      { id: 5, path: '/admin/tareas', title: 'Gestión Tareas',  icon:'fireplace', class: '', tieneHijos: false, visible: false, subMenu: [], oculto: false },
      //{ id: 6, path: '/admin/pendientes', title: 'Gestión Pendientes',  icon:'format_list_numbered', class: '', tieneHijos: false, visible: false, subMenu: [], oculto: false },
      { id: 6, path: '/admin/reportes', title: 'Reportes',  icon:'dashboard', class: '', tieneHijos: true, visible: false, subMenu: [
        { id: 1, path: '/admin/usuario2', title: 'Reservas-Casa',  icon: 'person', class: '', tieneHijos: false, visible: false, subMenu: [] },
        { id: 2, path: '/admin/membresias', title: 'Reservas-Socio',  icon: 'card_membership', class: '', tieneHijos: false, visible: false, subMenu: [] },
        { id: 3, path: '/admin/promociones', title: 'General-Reservas',  icon: 'sailing', class: '', tieneHijos: false, visible: false, subMenu: [] }
      ], oculto: false },
      { id: 7, path: '/admin/nuevo-evento', title: 'Nuevo Evento',  icon:'dashboard', class: '', tieneHijos: false, visible: false, subMenu: [], oculto: true },
      { id: 8, path: '/admin/nueva-reserva', title: 'Nueva Reserva',  icon:'dashboard', class: '', tieneHijos: false, visible: false, subMenu: [], oculto: true },
      { id: 9, path: '/admin/nueva-tarea', title: 'Nueva Tarea',  icon:'dashboard', class: '', tieneHijos: false, visible: false, subMenu: [], oculto: true },
      { id: 10, path: '/admin/detalle-tarea', title: 'Edición Tarea',  icon:'dashboard', class: '', tieneHijos: false, visible: false, subMenu: [], oculto: true },
      { id: 11, path: '/admin/detalle-evento', title: 'Edición Evento',  icon:'dashboard', class: '', tieneHijos: false, visible: false, subMenu: [], oculto: true },
      { id: 12, path: '/admin/nuevo-perfil', title: 'Nuevo Perfil',  icon:'dashboard', class: '', tieneHijos: false, visible: false, subMenu: [], oculto: true },
      { id: 13, path: '/admin/detalle-reserva', title: 'Edición Reserva',  icon:'dashboard', class: '', tieneHijos: false, visible: false, subMenu: [], oculto: true },
      { id: 14, path: '/admin/administracion', title: 'Administración',  icon:'dashboard', class: '', tieneHijos: true, visible: false, subMenu: [
        { id: 1, path: '/admin/mantenimiento-servicios', title: 'Mantenimiento-Servicios',  icon: 'person', class: '', tieneHijos: false, visible: false, subMenu: [] },
      ], oculto: false },
      { id: 15, path: '/admin/nuevo-servicio', title: 'Nuevo Servicio',  icon:'dashboard', class: '', tieneHijos: false, visible: false, subMenu: [], oculto: true },
      { id: 16, path: '/admin/detalle-servicio', title: 'Edición Servicio',  icon:'dashboard', class: '', tieneHijos: false, visible: false, subMenu: [], oculto: true },
    ];*/

    const itemUsuario = this.sessionService.Usuario;
    
    if(itemUsuario)
    {
      const resultado = await this.usuariosService.obtenerOpcionesPorUsuario(itemUsuario.id);

      const opciones = resultado.data.lista;

      this.sessionService.Opciones = opciones;

      this.menuItems = opciones;
    }
  }

  isMobileMenu() {
      if ($(window).width() > 991) {
          return false;
      }
      return true;
  };

  mostrarHijosOld(menuItem: RouteInfo)
  {
    menuItem.visible = !menuItem.visible;
  }

  filterMenuItemsOld(): any[] 
  {
    return this.menuItems.filter((x) => !x.oculto);
  }

  mostrarHijos(menuItem: ListadoOpciones)
  {
    menuItem.Colapsado = !menuItem.Colapsado;
  } 

  filterMenuItems(): ListadoOpciones[] 
  {
    return this.menuItems.filter((x) => x.VisibleMenu);
  }

  filterMenuItemsHijos(opciones: any[]): ListadoOpciones[] 
  {
    return opciones.filter((x) => x.VisibleMenu);
  }

}
