import { Lookup } from "src/app/shared/models/base/Lookup";
import { DatePipe } from "@angular/common";
import { User } from "../models/base/User";

export class Constantes {
  static readonly path = {
    home: "",
    msal: "msal",
    bandejaContratos: "contratos/bandeja",
    nuevoContrato: "contratos/nuevo",
    editarContrato: "contratos/editar/:idcontrato"
  };

  static readonly listas = {
    AdmUsuario: "AdmUsuario",
    AdmRol: "AdmRol",
    AdmPerfiles: "AdmPerfiles",
    Logs: "Logs",
    AdmTipoDocumento: 'AdmTipoDocumento',
    AdmTipoDocGestion: 'AdmTipoDocGestion',
    AdmTipoAlmacenamiento: 'AdmTipoAlmacenamiento',
    AdmSede: 'AdmSede',
    AdmArea: 'AdmArea',
    AdmPuesto: 'AdmPuesto',
    AdmMedioRecepcion: 'AdmMedioRecepcion',
    AdmEstadoDocumentoInterno: 'AdmEstadoDocumentoInterno',
    AdmPais: 'AdmPais',
    AdmTema: 'AdmTema',
    AdmProyecto: 'AdmProyecto',
    AdmParametrosGenerales: 'AdmParametrosGenerales',
    AdmZona: 'AdmZona',
    AdmLugaresArchivo: 'AdmLugaresArchivo',
    AdmContenedoresEstantes: 'AdmContenedoresEstantes',
    AdmVolumenes: 'AdmVolumenes',
    AdmCajas: 'AdmCajas',
    AdmOpcionesModulo: 'AdmOpcionesModulo',
    Contratos: 'Contratos'
  };

  static readonly columnas = {
    Id: "Id",
    ID: "ID",
    Title: "Title",
    EMail: "EMail",
    Modified: "Modified",
    Descripcion: "Descripcion",
    DescripcionError: "DescripcionError",
    Modulo: "Modulo",
    State: "State",
    Usuario: "Usuario",
    Sede: "Sede",
    Area: "Area",
    Puesto: "Puesto",
    AreaDestino: "AreaDestino",
    Habilitado: "Habilitado",
    Url: "Url",
    TipoPersona: 'TipoPersona',
    Longitud: 'Longitud',
    Valor: 'Valor',
    SedeArchivo: 'SedeArchivo',
    Ubicacion: 'Ubicacion',
    Estante: 'Estante',
    Caja: 'Caja',

    Codigo: "Codigo",
    CodigoExpediente: "CodigoExpediente",
    TipoDocumentoRemitente: "TipoDocumentoRemitente",
    NumeroDocumentoRemitente: "NumeroDocumentoRemitente",
    NombreRemitente: "NombreRemitente",
    ApellidoPaternoRemitente: "ApellidoPaternoRemitente",
    ApellidoMaternoRemitente: "ApellidoMaternoRemitente",
    RUC: "RUC",
    RazonSocialRemitente: "RazonSocialRemitente",
    CorreoRemitente: "CorreoRemitente",
    TelefonoRemitente: "TelefonoRemitente",
    UsuarioDestino: "UsuarioDestino",
    Asunto: "Asunto",
    Referencia: "Referencia",
    MedioRecepcion: "MedioRecepcion",
    TipoDocumentoPrincipal: "TipoDocumentoPrincipal",
    NumeroDocumentoPrincipal: "NumeroDocumentoPrincipal",
    TituloDocumentoPrincipal: "TituloDocumentoPrincipal",
    Titulo: "Titulo",
    Nota: "Nota",

    OpcionesModulo: "OpcionesModulo",

    FechaRegistro: "FechaRegistro",
    FechaModificacion: "FechaModificacion",
    FechaLimiteAtencion: "FechaLimiteAtencion",
    Tarea: "Tarea",
    Estado: "Estado",
  };

  static readonly mensajesPagina = {
  };

  static readonly headers = {
    "Content-Type": "application/json"
  }

  static readonly tipoParametros = {
    TipoAlmacenamiento: "Tipo de almacenamiento",
    TipoDocumento: "Tipo de documento",
    TipoDocumentoIdentidad: "Tipo de documento identidad",
  }

  static readonly ruteo = {
    Home: '/home',
    DetalleHome: '/:ubicacion/:enlace',
    InicioSesion: '/inicio-sesion',
    PagarReserva: '/reservar',
    BandejaEventos: '/admin/eventos',
    BandejaTareas: '/admin/tareas',
    BandejaReservas: '/admin/reservas',
    NuevoEvento: '/admin/nuevo-evento',
    DetalleEvento: '/admin/detalle-evento',
    NuevaTarea: '/admin/nueva-tarea',
    DetalleTarea: '/admin/detalle-tarea',
    NuevoParticipante: '/nuevo-participante',    
    NuevaReserva: '/admin/nueva-reserva',    
    DetalleReserva: '/admin/detalle-reserva',
    AgregarHuespedes: '/admin/agregar-huespedes',
    ListadoReservas: '/admin/listado-reservas',
    MantenimientoRoles:'/admin/mantenimiento-roles',
    NuevoRol: '/admin/nuevo-rol',
    DetalleRol: '/admin/detalle-rol',
    MantenimientoServicios:'/admin/mantenimiento-servicios',
    NuevoServicio: '/admin/nuevo-servicio',
    DetalleServicio: '/admin/detalle-servicio',
    MantenimientoPerfiles: '/admin/mantenimiento-perfiles',
    NuevoPerfil: '/admin/nuevo-perfil',
    DetallePerfil: '/admin/detalle-perfil',
    VisualizarOcupabilidad: '/admin/visualizar-ocupabilidad',
    MantenimientoCodigosDescuento: '/admin/mantenimiento-codigos-descuento',
    NuevoCodigoDescuento: '/admin/nuevo-codigo-descuento',
    DetalleCodigoDescuento: '/admin/detalle-codigo-descuento',
    MantenimientoBanner: '/admin/mantenimiento-banner',
    NuevoBanner: '/admin/nuevo-banner',
    DetalleBanner: '/admin/detalle-banner',
    Banner: '/banner',
    Politicas: '/politicas',
    PagarRealizado: '/pago-realizado',
    AgregarExtras: '/admin/agregar-extras',
    NuevoServicioGeneral: '/admin/nuevo-servicio-general',
    DetalleServicioGeneral: '/admin/detalle-servicio-general',
    BandejaServicios: '/admin/servicios',
    RegistrarUsuario: '/registro-usuario',
    PagoDirecto: '/admin/pago-directo',
    GenerarEnlacePago: '/admin/generar-enlace-pago',
    RealizarPago: '/realizar-pago'
  };

  static readonly estadoReservas = {
    ValidandoPago: 'Validando Pago',
    Reservado: 'Reservado',
    Anulado: 'Anulado',
    CheckIn: 'Check in',
    CheckOut: 'Check out',
    Cerrado: 'Cerrado'
  };

}