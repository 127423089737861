export class ListadoEmpresas {
    
    Id: string;
    Nombre: string;
    Logo: string;

    constructor() {
        this.Id = '';
        this.Nombre = '';
        this.Logo = '';
    }

    public static parseLista(element: any[]): ListadoEmpresas[]
    {
      let empresas: ListadoEmpresas[] = [];
      element.forEach(x => {
        const empresa = new ListadoEmpresas();
        empresa.Id = x.id;
        empresa.Nombre = x.nombre;
        empresa.Logo = x.logo;
        empresas.push(empresa);
      });      
      return empresas;
    }
}