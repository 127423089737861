import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FormularioBase } from 'src/app/shared/pages/FormularioBase';
import { EventosService } from 'src/app/shared/services/eventos.service';
import { MasterService } from 'src/app/shared/services/master.service';
import { UsuariosService } from 'src/app/shared/services/usuarios.service';
import { UtilService } from 'src/app/shared/services/util.service';
import { Constantes } from 'src/app/shared/utils/Constantes';
import { FormHelper } from 'src/app/shared/utils/form-helper';
import { ContraseniaValidator } from 'src/app/shared/validators/contraseniaValidator';

@Component({
  selector: 'app-resetear-contrasena',
  templateUrl: './resetear-contrasena.component.html',
  styleUrls: ['./resetear-contrasena.component.css']
})
export class ResetearContrasenaComponent extends FormularioBase implements OnInit {

  form: FormGroup;
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  hideNueva = true;
  hideNuevaR = true; 
  userId: string;
  constructor(
    public dialog: MatDialog,
    public route: ActivatedRoute,
    public router: Router,
    public spinner: NgxSpinnerService,
    public utilService: UtilService,
    public masterService: MasterService,
    public eventosService: EventosService,
    public formBuilder: FormBuilder,
    private toastr: ToastrService,
    public usuariosService: UsuariosService
  ) { 
    super('recuperar-contrasena', dialog, route, router, spinner, utilService, masterService);
    
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.userId = params['key'];
      

      this.usuariosService.resetearContraseniaInit({Llave: this.userId}).then(response=>{
        console.log(response);
        if(response.data === 'OK'){
          this.initForm();
        }
        else{
          this.toastr.error('Url invlaido.', 'Error!', {
            timeOut: 4000,
            progressBar: true,
            progressAnimation: 'increasing'
          });
          this.router.navigate([Constantes.ruteo.InicioSesion]);
          return;
        }
      });
    });
  }

  initForm(){
    this.form = new FormGroup({
      id: new FormControl(this.userId, [Validators.required]),
      contrasenia: new FormControl('', [Validators.required, Validators.minLength(8)]), //, ContraseniaValidator]),
      contraseniaRepetir: new FormControl('', [Validators.required, Validators.minLength(8)]),
    },{ validators: this.checkPasswords });
  }

  checkPasswords: ValidatorFn = (group: AbstractControl):  ValidationErrors | null => { 
    let pass = group.get('contrasenia').value;
    let confirmPass = group.get('contraseniaRepetir').value
    return pass === confirmPass ? null : { notSame: true }
  }

  onSave(){
    
    if (this.form.invalid) 
    {
      FormHelper.ValidarFormGroup(this.form);

      this.toastr.warning('Ingrese los campos obligatorios.', '¡Validación!');
      return;
    }
    
    this.mostrarProgreso();
    var body = this.form.getRawValue();
    console.log(body);
    this.usuariosService.ResetearContrasenia(body).then(response=>{
      console.log(response);
      if(response.data === 'OK')
      {
        this.toastr.success('Se ha actualizado correctamente.', '!Correcto!', {
          timeOut: 4000,
          progressBar: true,
          progressAnimation: 'increasing'
        });
        this.ocultarProgreso();
        this.router.navigate([Constantes.ruteo.InicioSesion]);
      }
      else{
        this.ocultarProgreso();
        this.toastr.error('Se encontró un problema en el envio del comunicado', '¡Error!');
      }
    });
  }

  onCancelar(){

  }
}
