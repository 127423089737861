import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BandejaEventosComponent } from './bandeja-eventos/bandeja-eventos.component';
import { BandejaPerfilesComponent } from './bandeja-perfiles/bandeja-perfiles.component';
import { BandejaReservasComponent } from './bandeja-reservas/bandeja-reservas.component';
import { NuevoEventoComponent } from './nuevo-evento/nuevo-evento.component';
import { ComunModule } from 'src/app/shared/comun.module';
import { BandejaTareasComponent } from './bandeja-tareas/bandeja-tareas.component';
import { NuevaReservaComponent } from './nueva-reserva/nueva-reserva.component';
import { NuevaTareaComponent } from './nueva-tarea/nueva-tarea.component';
import { NuevoPerfilComponent } from './nuevo-perfil/nuevo-perfil.component';
import { ServiciosComponent } from './administracion/servicios/servicios.component';
import { NuevoServicioComponent } from './administracion/nuevo-servicio/nuevo-servicio.component';
import { TinyEditorComponent } from './tiny-editor/tiny-editor.component';
import { RolesComponent } from './administracion/roles/roles.component';
import { NuevoRolComponent } from './administracion/nuevo-rol/nuevo-rol.component';
import { AgregarHuespedesComponent } from './agregar-huespedes/agregar-huespedes.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { VisualizarOcupabilidadComponent } from './visualizar-ocupabilidad/visualizar-ocupabilidad.component';
import { CodigosDescuentoComponent } from './administracion/codigos-descuento/codigos-descuento.component';
import { NuevoCodigoDescuentoComponent } from './administracion/nuevo-codigo-descuento/nuevo-codigo-descuento.component';
import { BannerComponent } from './administracion/banner/banner.component';
import { NuevoBannerComponent } from './administracion/nuevo-banner/nuevo-banner.component';
import { AgregarExtrasComponent } from './agregar-extras/agregar-extras.component';
import { BandejaServiciosComponent } from './bandeja-servicios/bandeja-servicios.component';
import { NuevoServicioGeneralComponent } from './nuevo-servicio-general/nuevo-servicio-general.component';
import { PagoDirectoComponent } from './pago-directo/pago-directo.component';
import { GenerarEnlacePagoComponent } from './generar-enlace-pago/generar-enlace-pago.component';

@NgModule({
  imports: [
    CommonModule,
    ComunModule,
    RouterModule,
  ],
  declarations: [
    BandejaEventosComponent,
    BandejaPerfilesComponent,
    BandejaReservasComponent,
    NuevoEventoComponent,
    BandejaTareasComponent,
    NuevaReservaComponent,
    NuevaTareaComponent,
    NuevoPerfilComponent,
    ServiciosComponent,
    NuevoServicioComponent,
    TinyEditorComponent,
    RolesComponent,
    NuevoRolComponent,
    AgregarHuespedesComponent,
    DashboardComponent,
    VisualizarOcupabilidadComponent,
    CodigosDescuentoComponent,
    NuevoCodigoDescuentoComponent,
    BannerComponent,
    NuevoBannerComponent,
    AgregarExtrasComponent,
    BandejaServiciosComponent,
    NuevoServicioGeneralComponent,
    PagoDirectoComponent,
    GenerarEnlacePagoComponent
  ],
  exports: [
    BandejaEventosComponent,
    BandejaPerfilesComponent,
    BandejaReservasComponent,
    NuevoEventoComponent,
    BandejaTareasComponent,
    NuevaReservaComponent,
    NuevaTareaComponent
  ],
  providers: [
    DatePipe
  ]
})
export class InternaModule { }