<div class="logo">
    <a class="simple-text logo-mini">
      <div class="logo-img" >
        <img src="./assets/inn/logo.png">
      </div>
    </a>
    <a routerLink="/home" class="simple-text logo-normal"> RESERVAS </a>
</div>
<div class="sidebar-wrapper">    
    <ul class="nav" id="accordion1">
        <li routerLinkActive="active" data-toggle="collapse" *ngFor="let menuItem of filterMenuItems()" class="nav-item">
            <a class="nav-link" [routerLink]="[menuItem.Ruta]" *ngIf="!menuItem.EsOpcionPadre">
                <i class="material-icons">{{menuItem.Icono}}</i>
                <p>{{menuItem.Nombre}} </p>
            </a>
            <a data-toggle="collapse" (click)="mostrarHijos(menuItem)" class="nav-link {{menuItem.Colapsado ? '': 'collapsed'}}" aria-expanded="false"
               *ngIf="menuItem.EsOpcionPadre">
                <i class="material-icons">{{menuItem.Icono}}</i>
                <p>{{menuItem.Nombre}} <b class="caret"></b></p>
            </a>
            <div [ngClass]="{ 'collapse show': menuItem.Colapsado, 'collapse': !menuItem.Colapsado }" *ngIf="menuItem.EsOpcionPadre">
                <ul class="nav">
                  <li routerLinkActive="active" class="nav-item" *ngFor="let subMenuItem of filterMenuItemsHijos(menuItem.Opciones)">
                    <a class="nav-link" [routerLink]="[subMenuItem.Ruta]">
                      <i class="material-icons">{{subMenuItem.Icono}}</i>
                      <span class="sidebar-normal">{{subMenuItem.Nombre}}</span>
                    </a>
                  </li>             
                </ul>
            </div>
        </li>      
    </ul>    
</div>
