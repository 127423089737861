<div>
    <div class="container">
        <mat-card class="border-custom-2">  
            <!--<form [formGroup]="form">-->
                <div class="row centrado-participante" *ngIf="sinIdentificadorEvento">
                    <div class="col-lg-12">
                        <h2 class="titulo">{{mensajeErrorPagina}}</h2>
                    </div>
                </div>
                <div class="row" *ngIf="!sinIdentificadorEvento">  
                    <div class="col-lg-12 mb-4">
                        <img src="{{rutaBanner}}" class="img-fluid h-auto" alt="">
                    </div>
                    <div class="col-lg-12 align-items-center">                        
                        <div class="row centrado-participante" *ngIf="registroRealizado">                            
                            <div class="col-lg-9">
                                <h2 class="titulo">¡Gracias por participar ! en breve le llegará un correo de confirmación del registro realizado.</h2>
                            </div>
                        </div>
                        <div class="row centrado-participante" *ngIf="!registroRealizado">                            
                            <div class="col-lg-9">
                                <h2 class="titulo">{{nombreEvento}}</h2>
                            </div>
                            <div class="col-lg-9">
                                <p class="subtitulo">{{descripcionEvento}}</p>
                            </div>
                            <div class="row justify-content-center" *ngFor="let item of ItemsPreguntas; let i = index">
                                <mat-form-field class="col-lg-9"  appearance="outline" *ngIf="item.tipoPregunta === 'Texto Corto' && item.tipoDato === 'Texto'"
                                    [ngClass]="{ '': !mostrarMensajeObligatorio, 'mat-form-field-invalid': item.obligatorio && esUndefinedNullOrEmpty(item.respuestaPregunta) && mostrarMensajeObligatorio }">
                                    <mat-label>{{item.nombrePregunta}}
                                        <span *ngIf="item.obligatorio && !mostrarMensajeObligatorio" class="">*</span>
                                        <span *ngIf="item.obligatorio && mostrarMensajeObligatorio" class="mat-placeholder-required mat-form-field-required-marker">*</span>
                                    </mat-label>
                                    <input aria-label="text" matInput autocomplete="off" 
                                    [(ngModel)]="item.respuestaPregunta" maxlength="100" (change)="onChangeInput(item, $event)">
                                    <mat-hint class="mat-error" *ngIf="item.obligatorio && esUndefinedNullOrEmpty(item.respuestaPregunta) && mostrarMensajeObligatorio">Campo obligatorio</mat-hint>
                                </mat-form-field>
                                <mat-form-field class="col-lg-9"  appearance="outline" *ngIf="item.tipoPregunta === 'Texto Corto' && item.tipoDato === 'Número'"
                                    [ngClass]="{ '': !mostrarMensajeObligatorio, 'mat-form-field-invalid': item.obligatorio && esUndefinedNullOrEmpty(item.respuestaPregunta) && mostrarMensajeObligatorio }">
                                    <mat-label>{{item.nombrePregunta}}
                                        <span *ngIf="item.obligatorio && !mostrarMensajeObligatorio" class="">*</span>
                                        <span *ngIf="item.obligatorio && mostrarMensajeObligatorio" class="mat-placeholder-required mat-form-field-required-marker">*</span>
                                    </mat-label>
                                    <input aria-label="text" matInput onkeypress="return event.charCode >= 48 && event.charCode <= 57" autocomplete="off" 
                                    [(ngModel)]="item.respuestaPregunta" maxlength="100" (change)="onChangeInput(item, $event)">
                                    <mat-hint class="mat-error" *ngIf="item.obligatorio && esUndefinedNullOrEmpty(item.respuestaPregunta) && mostrarMensajeObligatorio">Campo obligatorio</mat-hint>
                                </mat-form-field>
                                <mat-form-field class="col-lg-9"  appearance="outline" *ngIf="item.tipoPregunta === 'Texto Corto' && item.tipoDato === 'Correo'"
                                    [ngClass]="{ '': !mostrarMensajeObligatorio, 'mat-form-field-invalid': item.obligatorio && esUndefinedNullOrEmpty(item.respuestaPregunta) && mostrarMensajeObligatorio }">
                                    <mat-label>{{item.nombrePregunta}}
                                        <span *ngIf="item.obligatorio && !mostrarMensajeObligatorio" class="">*</span>
                                        <span *ngIf="item.obligatorio && mostrarMensajeObligatorio" class="mat-placeholder-required mat-form-field-required-marker">*</span>
                                    </mat-label>
                                    <input aria-label="text" matInput autocomplete="off" (keyup)="ValidarSoloEmail($event, item.respuestaPregunta)"
                                    [(ngModel)]="item.respuestaPregunta" maxlength="100" (change)="onChangeInput(item, $event)">
                                    <mat-hint class="mat-error" *ngIf="item.obligatorio && esUndefinedNullOrEmpty(item.respuestaPregunta) && mostrarMensajeObligatorio">Campo obligatorio</mat-hint>
                                    <mat-hint class="mat-error" *ngIf="mostrarMensajeErrorEmail">Ingrese un correo válido.</mat-hint>
                                </mat-form-field>
                                <mat-form-field class="col-lg-9" appearance="outline" *ngIf="item.tipoPregunta === 'Subir Archivo'" 
                                    [ngClass]="{ '': !mostrarMensajeObligatorio, 'mat-form-field-invalid': item.obligatorio && mostrarMensajeObligatorio }">
                                    <mat-label>{{item.nombrePregunta}}
                                        <span *ngIf="item.obligatorio && !mostrarMensajeObligatorio" class="">*</span>
                                        <span *ngIf="item.obligatorio && mostrarMensajeObligatorio" class="mat-placeholder-required mat-form-field-required-marker">*</span>
                                    </mat-label>
                                    <input aria-label="text" matInput readonly autocomplete="off" [(ngModel)]="item.nombreArchivo" maxlength="250">
                                    <mat-icon matSuffix (click)="onClickFileUpload('fileAdjuntos'+item.id)">upload</mat-icon>
                                    <input type="file" id="fileAdjuntos{{item.id}}" [hidden]="true" (change)="onFileUpload('fileAdjuntos'+item.id, item, $event)">
                                    <mat-hint class="mat-error" *ngIf="item.obligatorio && mostrarMensajeObligatorio">Campo obligatorio</mat-hint>
                                </mat-form-field>
                                <div class="col-lg-9" *ngIf="item.tipoPregunta === 'Varias Opciones'">
                                    <div style="text-align: left;">{{item.nombrePregunta}}
                                        <span *ngIf="item.obligatorio && !mostrarMensajeObligatorio" class="">*</span>
                                        <span *ngIf="item.obligatorio && mostrarMensajeObligatorio" class="mat-placeholder-required mat-form-field-required-marker">*</span>
                                    </div>
                                    <mat-radio-group aria-labelledby="example-radio-group-label" class=" example-radio-group"
                                        [(ngModel)]="item.respuestaPregunta" (change)="onChangeRadioButtom(item, $event)">
                                        <mat-radio-button class="example-radio-button" *ngFor="let valorPregunta of item.valoresPregunta" [value]="valorPregunta">
                                            {{valorPregunta}}
                                        </mat-radio-button>
                                    </mat-radio-group>
                                    <mat-hint class="mat-error" style="text-align: left;" *ngIf="item.obligatorio && esUndefinedNullOrEmpty(item.respuestaPregunta) && mostrarMensajeObligatorio">Campo obligatorio</mat-hint>
                                </div>                                
                            </div>
                            <div class="col-lg-9 mb-4 mt-4">
                                <mat-checkbox class="example-margin" [(ngModel)]="autorizacionDatos">
                                    Autorizo el tratamiento de mis datos personales a {{nombreEmpresa}} con la finalidad que pueda registrar mi participación
                                </mat-checkbox>
                            </div>
                            <div class="col-lg-9 mb-4 mt-4">
                                <button mat-flat-button class="btn-primario" (click)="onEnviarClick()">Registrar Participación</button>
                            </div>
                        </div>
                    </div>
                </div>
            <!--</form>-->
        </mat-card>
    </div>
</div>