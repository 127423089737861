import { RespuestaPaginada } from "../base/respuesta-paginada";
import { RespuestaBaseLista } from "../base/RespuestaBase";

export class ListadoServicios {
    
    Id: string;
    Nombre: string;
    NombreCategoria: string;
    NombreDistrito: string;
    DescripcionCorta: string;
    FechaRegistro: Date;
    Costo: number;
    Ranking: number;
    Destacado: boolean;
    UrlImagenPrincipal: string;
    Enlace: string;
    Socio: string;
    Estado: string;

    constructor() {
        this.Id = '';
        this.Nombre = '';
        this.DescripcionCorta = '';
        this.UrlImagenPrincipal = '';
    }

    public static parseLista(element: any[]): ListadoServicios[]
    {
      let registros: ListadoServicios[] = [];
      element.forEach(x => {
        const registro = new ListadoServicios();
        registro.Id = x.id;
        registro.Nombre = x.nombre;
        registro.DescripcionCorta = x.descripcionCorta;
        registro.Costo = x.costo;
        registro.Ranking = x.ranking;
        registro.Destacado = x.destacado;
        registro.Enlace = x.enlace;
        registro.Socio = x.socio;
        registro.UrlImagenPrincipal = x.foto != null ? x.foto.url : '';
        registros.push(registro);
      });
      return registros;
    }

    public static parseListaBandeja(element: any): RespuestaBaseLista<ListadoServicios>
    {
      let respuesta = {} as RespuestaBaseLista<ListadoServicios>;
      respuesta.data = {} as RespuestaPaginada<ListadoServicios>;
      let registros: ListadoServicios[] = [];
      element.data.lista.forEach(x => {
        const registro = new ListadoServicios();
        registro.Id = x.id;
        registro.Nombre = x.nombre;
        registro.NombreCategoria = x.nombreCategoria;
        registro.Costo = x.costo;
        registro.NombreDistrito = x.nombreDistrito;        
        registro.FechaRegistro = x.fechaRegistro;        
        registro.Estado = x.estado;
        registros.push(registro);
      });      
      respuesta.data.lista = registros;
      respuesta.data.total = element.data.total;
      return respuesta;
    }
}