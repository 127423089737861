import { RespuestaBaseLista } from "../base/RespuestaBase";
import { RespuestaPaginada } from "../base/respuesta-paginada";
import { Funciones } from "../../utils/Funciones";

export class ListadoReservas {
    
    Id: string;
    CodigoReserva: string;
    NombreCategoriaServicio: string;
    NombreServicio: string;
    NombreUsuario: string;
    FechaRegistro: Date;
    FechaHoraRegistro: string;
    FechaLlegada: Date;
    FechaSalida: Date;
    FechaHoraLlegada: string;
    FechaHoraSalida: string;
    Cantidad: number;
    Precio: number;
    CostoTotal: number;
    DiaRegistro: number;
    Estado: string;
    Leyenda: string;

    constructor() {
        this.Id = '';
        this.NombreServicio = '';
        this.NombreCategoriaServicio = '';
        this.NombreUsuario = '';
        this.Estado = '';
    }

    public static parseLista(element: any): RespuestaBaseLista<ListadoReservas>
    {
      let respuesta = {} as RespuestaBaseLista<ListadoReservas>;
      respuesta.data = {} as RespuestaPaginada<ListadoReservas>;
      let registros: ListadoReservas[] = [];
      element.data.lista.forEach(x => {
        const registro = new ListadoReservas();
        registro.Id = x.id;
        registro.CodigoReserva = x.codigoReserva;
        registro.NombreServicio = x.nombreServicio;
        registro.NombreCategoriaServicio = x.nombreCategoriaServicio;
        registro.NombreUsuario = x.nombreUsuario;
        registro.FechaRegistro = x.fechaRegistro;
        registro.FechaHoraRegistro = Funciones.ConvertirDateToString2(x.fechaRegistro);
        registro.FechaHoraLlegada = Funciones.ConcatenarFechaHoraLLegada(x.fechaLlegada, x.horaIngresoServicio);
        registro.FechaHoraSalida = Funciones.ConcatenarFechaHoraSalida(x.fechaSalida, x.horaSalidaServicio);
        registro.FechaLlegada = x.fechaLlegada;
        registro.FechaSalida = x.fechaSalida;
        registro.Cantidad = x.cantidad;
        registro.Precio = x.precio;
        registro.CostoTotal = x.costoTotal;
        registro.DiaRegistro = x.diaRegistro;
        registro.Estado = x.estado;
        registro.Leyenda = x.leyenda;
        registros.push(registro);
      });      
      respuesta.data.lista = registros;
      respuesta.data.total = element.data.total;
      return respuesta;
    }
}