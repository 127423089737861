<nav class="navbar navbar-expand-lg navbar-transparent navbar-absolute">
    <div class="container-fluid">
      <div class="navbar-wrapper">
        <div class="navbar-minimize">
          <button mat-raised-button="" class="btn btn-just-icon btn-white btn-fab btn-round" (click)="OpenMenu()">
            <i class="material-icons text_align-center visible-on-sidebar-regular">more_vert</i>
            <i class="material-icons design_bullet-list-67 visible-on-sidebar-mini">view_list</i>
          </button>
        </div>
        <!--<a class="navbar-brand" href="#/charts"> Charts</a>-->
        <a class="navbar-brand" style="font-weight: 500;" href="javascript:void(0)">{{getTitle()}}</a>
      </div>
      <button class="navbar-toggler" type="button" (click)="sidebarToggle()">
        <span class="sr-only">Toggle navigation</span>
        <span class="navbar-toggler-icon icon-bar"></span>
        <span class="navbar-toggler-icon icon-bar"></span>
        <span class="navbar-toggler-icon icon-bar"></span>
      </button>
      <div id="navigation" class="collapse navbar-collapse justify-content-end">
        <!--<form class="navbar-form">
          <div class="input-group no-border">
            <input type="text" value="" placeholder="Search..." class="form-control">
            <button mat-icon-button="" type="submit" class=" ">
              <i class="material-icons">search</i>
              <div class="ripple-container"></div>
            </button>
          </div>
        </form>-->
        <ul class="navbar-nav">
          <!--<li class="nav-item">
            <a href="#pablo" class="nav-link">
              <i class="material-icons">dashboard</i>
              <p>
                <span class="d-lg-none d-md-block">Stats</span>
              </p>
            </a>
          </li>-->
          <li class="nav-item dropdown" *ngIf="false">
            <a href="#pablo" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="nav-link">
              <i class="material-icons">notifications</i>
              <span class="notification">0</span>
              <p>
                <span class="d-lg-none d-md-block">Some Actions</span>
              </p>
            </a>
            <div aria-labelledby="navbarDropdownMenuLink" class="dropdown-menu dropdown-menu-right">
              <a href="#" class="dropdown-item">Mike John responded to your email</a>
              <a href="#" class="dropdown-item">You have 5 new tasks</a>
              <a href="#" class="dropdown-item">You're now friend with Andrew</a>
              <a href="#" class="dropdown-item">Another Notification</a>
              <a href="#" class="dropdown-item">Another One</a>
            </div>
          </li>
          <li class="nav-item">
            <div class="perfil d-flex align-items-center">
              {{nombreUsuario}} <button mat-icon-button [matMenuTriggerFor]="menu"> <mat-icon>person</mat-icon> </button>
              <mat-menu #menu="matMenu">                  
                  <button mat-menu-item (click)="onCerrarSesion()">
                      <span>Cerrar Sesión</span>
                  </button>
              </mat-menu>
            </div>
            <!--<a href="#pablo" class="nav-link">
              <i class="material-icons">person</i>
              <p><span class="d-lg-none d-md-block">Account</span></p>
            </a>-->
          </li>
        </ul>
      </div>
    </div>
</nav>