import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { ListadoTipoServicios } from "../models/response/listado-tipo-servicios";
import { ClienteApiHttpService } from "./cliente-api-http.service";
import { ListadoEmpresas } from "../models/response/listado-empresas";
import { ListadoCategorias } from "../models/response/listar-categorias";
import { ListadoServicios } from "../models/response/listado-servicios";
import { ListadoBanners } from "../models/response/listado-banners";
import { CodigoDescuento } from "../models/response/codigo-descuento";
import { ListadoProvincias } from "../models/response/listado-provincias";
import { ListadoDistritos } from "../models/response/listado-distritos";
import { Funciones } from "../utils/Funciones";
import { RespuestaBaseDetalle } from "../models/base/RespuestaBase";

@Injectable({
  providedIn: "root"
})
export class MasterService {

  private uriObtenerTipoServicios: string = environment.apiUrlBackEnd + '/api/maestro/listar-tipo-servicios';
  private uriObtenerEmpresas: string = environment.apiUrlBackEnd + '/api/maestro/listar-empresas';
  private uriObtenerCategorias: string = environment.apiUrlBackEnd + '/api/maestro/listar-categorias';
  private uriObtenerServicios: string = environment.apiUrlBackEnd + '/api/maestro/listar-servicios';
  private uriObtenerBanners: string = environment.apiUrlBackEnd + '/api/maestro/listar-banners';
  private uriObtenerCodigoDescuento: string = environment.apiUrlBackEnd + '/api/maestro/obtener-codigo-descuento';
  private uriObtenerServiciosAlojamiento: string = environment.apiUrlBackEnd + '/api/maestro/listar-servicios-alojamiento';
  private uriObtenerProvincias: string = environment.apiUrlBackEnd + '/api/maestro/listar-provincias';
  private uriObtenerDistritos: string = environment.apiUrlBackEnd + '/api/maestro/listar-distritos';
  private uriObtenerPoliticas: string = environment.apiUrlBackEnd + '/api/maestro/obtener-politicas';
  private uriObtenerServiciosAlojamientoPorSocio: string = environment.apiUrlBackEnd + '/api/maestro/listar-servicios-alojamiento-socio';
  private uriObtenerTodosServicios: string = environment.apiUrlBackEnd + '/api/maestro/listar-todos-servicios';

  constructor(private clienteApiHtppService: ClienteApiHttpService) {
    
  }
  
  public obtenerTipoServicios(): Promise<ListadoTipoServicios[]> {
    return new Promise<any>((resolve, reject) => {
      this.clienteApiHtppService.get(this.uriObtenerTipoServicios).subscribe(
        (res: any) => {
          resolve(ListadoTipoServicios.parseLista(res))
        },
        (error: any) => reject(error)
      );
    });
  }

  public obtenerEmpresas(): Promise<ListadoEmpresas[]> {
    return new Promise<any>((resolve, reject) => {
      this.clienteApiHtppService.get(this.uriObtenerEmpresas).subscribe(
        (res: any) => {
          resolve(ListadoEmpresas.parseLista(res))
        },
        (error: any) => reject(error)
      );
    });
  }

  public obtenerCategorias(): Promise<ListadoCategorias[]> {
    return new Promise<any>((resolve, reject) => {
      this.clienteApiHtppService.get(this.uriObtenerCategorias).subscribe(
        (res: any) => {
          resolve(ListadoCategorias.parseLista(res))
        },
        (error: any) => reject(error)
      );
    });
  }

  public obtenerServicios(body: any): Promise<ListadoServicios[]> 
  {
    return new Promise<any>((resolve, reject) => {
      this.clienteApiHtppService.post(this.uriObtenerServicios, body).subscribe(
        (res: any) => {
          resolve(ListadoServicios.parseLista(res))
        },
        (error: any) => reject(error)
      );
    });
  }

  public obtenerBanners(idEmpresa: string): Promise<ListadoBanners[]> {

    const url = `${this.uriObtenerBanners}?idEmpresa=${idEmpresa}`;

    return new Promise<any>((resolve, reject) => {
      this.clienteApiHtppService.get(url).subscribe(
        (res: any) => {
          resolve(ListadoBanners.parseLista(res))
        },
        (error: any) => reject(error)
      );
    });
  }  

  public obtenerCodigoDescuento(codigo: string): Promise<CodigoDescuento> {

    const url = `${this.uriObtenerCodigoDescuento}?codigo=${codigo}`;

    return new Promise<any>((resolve, reject) => {
      this.clienteApiHtppService.get(url).subscribe(
        (res: any) => {
          resolve(CodigoDescuento.parse(res))
        },
        (error: any) => reject(error)
      );
    });
  }

  public obtenerServiciosAlojamiento(idEmpresa: string): Promise<ListadoServicios[]> {

    const url = `${this.uriObtenerServiciosAlojamiento}?idEmpresa=${idEmpresa}`;

    return new Promise<any>((resolve, reject) => {
      this.clienteApiHtppService.get(url).subscribe(
        (res: any) => {
          resolve(ListadoServicios.parseLista(res))
        },
        (error: any) => reject(error)
      );
    });
  }

  public obtenerProvincias(): Promise<ListadoProvincias[]> {
    return new Promise<any>((resolve, reject) => {
      this.clienteApiHtppService.get(this.uriObtenerProvincias).subscribe(
        (res: any) => {
          resolve(ListadoProvincias.parseLista(res))
        },
        (error: any) => reject(error)
      );
    });
  }

  public obtenerDistritos(): Promise<ListadoDistritos[]> {
    return new Promise<any>((resolve, reject) => {
      this.clienteApiHtppService.get(this.uriObtenerDistritos).subscribe(
        (res: any) => {
          resolve(ListadoDistritos.parseLista(res))
        },
        (error: any) => reject(error)
      );
    });
  }

  public obtenerPoliticas(nombreArchivo: string): Promise<RespuestaBaseDetalle<string>>
  {
    const url = `${this.uriObtenerPoliticas}?politica=${nombreArchivo}`;

    return new Promise<any>((resolve, reject) => {
      this.clienteApiHtppService.get(url).subscribe(
        resultado => {
          resolve(resultado)
        },
        (error: any) => reject(error)
      );
    });
    /*return new Promise<string>((resolve, reject) => {      
      this.httpClient.post(this.uriExportarParticipantesEvento, body).subscribe(
        (resultado: any) => {
          const blob = Funciones.obtenerBlobDesdeBase64(resultado.data);            
          const url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          a.download = nombreArchivo;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
          resolve(nombreArchivo);
        },
        (error: any) => reject(error)
      );
    });*/
  }

  public obtenerServiciosAlojamientoPorSocio(idEmpresa: string, idSocio: string): Promise<ListadoServicios[]> {

    const url = `${this.uriObtenerServiciosAlojamientoPorSocio}?idEmpresa=${idEmpresa}&idSocio=${idSocio}`;

    return new Promise<any>((resolve, reject) => {
      this.clienteApiHtppService.get(url).subscribe(
        (res: any) => {
          resolve(ListadoServicios.parseLista(res))
        },
        (error: any) => reject(error)
      );
    });
  }

  public obtenerTodosServicios(idEmpresa: string): Promise<ListadoServicios[]> {

    const url = `${this.uriObtenerTodosServicios}?idEmpresa=${idEmpresa}`;

    return new Promise<any>((resolve, reject) => {
      this.clienteApiHtppService.get(url).subscribe(
        (res: any) => {
          resolve(ListadoServicios.parseLista(res))
        },
        (error: any) => reject(error)
      );
    });
  }

}
