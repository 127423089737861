export function soloNumerosMonto(charCode: any): boolean
{
    return charCode <= 13 || (charCode >= 48 && charCode <= 57) || charCode == 8 || charCode == 46;
}

export function esEmailValido(charCode: any): boolean 
{
    let esValido = false;

    if (charCode != '') {
      const pattern = new RegExp(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      esValido = pattern.test(charCode.toString());
    }
    return esValido;
}