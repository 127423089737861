import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AccesoUsuario } from '../../models/response/acceso-usuario';
import { filter } from 'rxjs/operators';
import { Constantes } from '../../utils/Constantes';
import { SessionService } from '../../services/session.service';

@Component({
  selector: 'app-web-layout',
  templateUrl: './web-layout.component.html',
  styleUrls: ['./web-layout.component.css']
})
export class WebLayoutComponent implements OnInit {

  sinLogueo = true;
  nombreUsuario = '';
  AbrirModalWhatssapp = false;
  contenido = '';
  
  constructor(private router: Router,
              private sessionService: SessionService) 
  { 
    router.events.pipe(
      filter(event => event instanceof NavigationEnd)
      )
      .subscribe((event: any) => 
      {
          this.ngOnInit();
      });
  }

  ngOnInit(): void 
  {
    const datosUsuario = this.sessionService.Usuario;
    
    if(datosUsuario != null)
    {
      this.sinLogueo = datosUsuario == null || datosUsuario.id == '';
      this.nombreUsuario = datosUsuario.nombreUsuario;
    }
  }

  onCerrarSesion()
  {
    this.sessionService.CerrarSession();
    this.sinLogueo = true;
    this.nombreUsuario = '';
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
    this.router.navigate([Constantes.ruteo.Home]));
  }

  onIrInicioSesion()
  {
    const pagina = this.router.url.split('?');

    if(pagina.length > 0 && pagina[0] === Constantes.ruteo.PagarReserva)
    {
      const url = Constantes.ruteo.InicioSesion;
      this.router.navigate([url], { queryParams: { source: this.router.url } });
    }
    else
    {
      const url = Constantes.ruteo.InicioSesion;
      this.router.navigate([url]);
    }
    
  }

  onAbrirChat()
  {
    const url = 'https://api.whatsapp.com/send/?phone=51925127336&text=Hola+Necesito+m%C3%A1s+informaci%C3%B3n&type=phone_number&app_absent=0';
    window.open(url, '_blank');
  }

  onBuscar()
  {
    const url = Constantes.ruteo.Home;
    
    if(this.contenido)
    {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      {
        this.router.navigate([url], { queryParams: { search: this.contenido } })
      });
    }
    else
    {
      this.router.navigate([url]);
    }    
  }

  onIrRegistrarse()
  {
    const pagina = this.router.url.split('?');

    if(pagina.length > 0 && pagina[0] === Constantes.ruteo.PagarReserva)
    {
      const url = Constantes.ruteo.RegistrarUsuario;
      this.router.navigate([url], { queryParams: { source: this.router.url } });
    }
    else
    {
      const url = Constantes.ruteo.RegistrarUsuario;
      this.router.navigate([url]);
    }
    
  }

}
