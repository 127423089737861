import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ClienteApiHttpService } from './cliente-api-http.service';
import { Router, UrlSerializer } from '@angular/router';
import { sp } from '@pnp/sp';
import pnp from '@pnp/pnpjs';
import { Deferred } from 'ts-deferred';
import { Constantes } from 'src/app/shared/utils/Constantes';
import { RespuestaBaseDetalle, RespuestaBaseLista } from '../models/base/RespuestaBase';
import { HttpClient } from '@angular/common/http';
import { Evento } from '../models/response/evento';
import { ListadoEventos } from '../models/response/listado-eventos';
import { ListadoReservas } from '../models/response/listado-reservas';
import { Reserva } from '../models/response/reserva';
import { ListadoPagos } from '../models/response/listado-pagos';

@Injectable({ providedIn: 'root' })
export class ReservasService {
  
  private uriRegistrarParticipante: string = environment.apiUrlBackEnd + '/api/evento/registrar-participante';
  private uriObtenerEventosBandeja: string = environment.apiUrlBackEnd + '/api/evento/listar-eventos-bandeja';
  private uriObtenerReservasBandeja: string = environment.apiUrlBackEnd + '/api/reserva/listar-reservas-bandeja';
  private uriObtenerCantidadReservas: string = environment.apiUrlBackEnd + '/api/reserva/obtener-cantidad-reservas';
  private uriRegistrarReserva: string = environment.apiUrlBackEnd + '/api/reserva/registrar-reserva';
  private uriObtenerReserva: string = environment.apiUrlBackEnd + '/api/reserva/obtener-reserva';
  private uriValidarPago: string = environment.apiUrlBackEnd + '/api/reserva/validar-pago';
  private uriRegistrarIngresoReserva: string = environment.apiUrlBackEnd + '/api/reserva/registrar-ingreso-reserva';
  private uriRegistrarSalidaReserva: string = environment.apiUrlBackEnd + '/api/reserva/registrar-salida-reserva';
  private uriRegistrarLiberacionReserva: string = environment.apiUrlBackEnd + '/api/reserva/registrar-liberacion-reserva';
  private uriObtenerReservasPorUsuario: string = environment.apiUrlBackEnd + '/api/reserva/listar-reservas-usuario';
  private uriRegistrarHuesped: string = environment.apiUrlBackEnd + '/api/reserva/registrar-huesped';
  private uriModificarHuesped: string = environment.apiUrlBackEnd + '/api/reserva/modificar-huesped';
  private uriEliminarHuesped: string = environment.apiUrlBackEnd + '/api/reserva/eliminar-huesped';
  private uriObtenerReservasPorMes: string = environment.apiUrlBackEnd + '/api/reserva/listar-reservas-mes';
  private uriAnularReserva: string = environment.apiUrlBackEnd + '/api/reserva/anular-reserva';
  
  constructor(
    private clienteApiHtppService: ClienteApiHttpService,
    private httpClient: HttpClient,
    private router: Router,
    private serializer: UrlSerializer) {
  }

  public registrarParticipante(body: any): Promise<RespuestaBaseDetalle<string>> {
    
    return new Promise<RespuestaBaseDetalle<string>>(resolve => {
      this.httpClient.post<RespuestaBaseDetalle<string>>(this.uriRegistrarParticipante, body).subscribe(res => {
        resolve(res);
      });
    });
  }

  public obtenerEventos(body: any): Promise<RespuestaBaseLista<ListadoEventos>> {
    return new Promise<RespuestaBaseLista<ListadoEventos>>((resolve, reject) => {
      this.clienteApiHtppService.post(this.uriObtenerEventosBandeja, body).subscribe(
        res => {
          resolve(ListadoEventos.parseLista(res))
        },
        (error: any) => reject(error)
      );
    });
  }

  public obtenerReservas(body: any): Promise<RespuestaBaseLista<ListadoReservas>> {
    return new Promise<RespuestaBaseLista<ListadoReservas>>((resolve, reject) => {
      this.clienteApiHtppService.post(this.uriObtenerReservasBandeja, body).subscribe(
        res => {
          resolve(ListadoReservas.parseLista(res))
        },
        (error: any) => reject(error)
      );
    });
  }

  public obtenerCantidadReservas(body: any): Promise<RespuestaBaseDetalle<number>> {
    return new Promise<RespuestaBaseDetalle<number>>((resolve, reject) => {
      this.clienteApiHtppService.post(this.uriObtenerCantidadReservas, body).subscribe(
        res => {
          resolve(res)
        },
        (error: any) => reject(error)
      );
    });
  }

  public registrarReserva(body: any): Promise<RespuestaBaseDetalle<any>> {
    
    return new Promise<RespuestaBaseDetalle<any>>(resolve => {
      this.httpClient.post<RespuestaBaseDetalle<any>>(this.uriRegistrarReserva, body).subscribe(res => {
        resolve(res);
      });
    });
  }

  public obtenerReserva(id: string): Promise<RespuestaBaseDetalle<Reserva>> {

    const url = `${this.uriObtenerReserva}?id=${id}`;
    return new Promise<RespuestaBaseDetalle<Reserva>>(resolve => {
      this.httpClient.get<RespuestaBaseDetalle<Reserva>>(url).subscribe(res => {
        resolve(res);
      });
    });
  }

  public validarPago(body: any): Promise<RespuestaBaseDetalle<string>> {
    
    return new Promise<RespuestaBaseDetalle<string>>(resolve => {
      this.httpClient.post<RespuestaBaseDetalle<string>>(this.uriValidarPago, body).subscribe(res => {
        resolve(res);
      });
    });
  }

  public registrarIngreso(body: any): Promise<RespuestaBaseDetalle<string>> {
    
    return new Promise<RespuestaBaseDetalle<string>>(resolve => {
      this.httpClient.post<RespuestaBaseDetalle<string>>(this.uriRegistrarIngresoReserva, body).subscribe(res => {
        resolve(res);
      });
    });
  }

  public registrarSalida(body: any): Promise<RespuestaBaseDetalle<string>> {
    
    return new Promise<RespuestaBaseDetalle<string>>(resolve => {
      this.httpClient.post<RespuestaBaseDetalle<string>>(this.uriRegistrarSalidaReserva, body).subscribe(res => {
        resolve(res);
      });
    });
  }

  public registrarLiberacion(body: any): Promise<RespuestaBaseDetalle<string>> {
    
    return new Promise<RespuestaBaseDetalle<string>>(resolve => {
      this.httpClient.post<RespuestaBaseDetalle<string>>(this.uriRegistrarLiberacionReserva, body).subscribe(res => {
        resolve(res);
      });
    });
  }

  public obtenerReservasPorUsuario(idUsuario: string): Promise<RespuestaBaseLista<ListadoReservas>> {

    const url = `${this.uriObtenerReservasPorUsuario}?idUsuario=${idUsuario}`;
    return new Promise<RespuestaBaseLista<ListadoReservas>>(resolve => {
      this.httpClient.get<RespuestaBaseLista<ListadoReservas>>(url).subscribe(res => {
        resolve(ListadoReservas.parseLista(res));
      });
    });
  }

  public registrarHuesped(body: any): Promise<RespuestaBaseDetalle<boolean>> {
    
    return new Promise<RespuestaBaseDetalle<boolean>>(resolve => {
        this.httpClient.post<RespuestaBaseDetalle<boolean>>(this.uriRegistrarHuesped, body).subscribe(res => {
        resolve(res);
        });
    });
}

public modificarHuesped(body: any): Promise<RespuestaBaseDetalle<boolean>> {

    return new Promise<RespuestaBaseDetalle<boolean>>(resolve => {
        this.httpClient.post<RespuestaBaseDetalle<boolean>>(this.uriModificarHuesped, body).subscribe(res => {
        resolve(res);
        });
    });
}

public eliminarHuesped(idHuesped: string): Promise<boolean> {

    const url = `${this.uriEliminarHuesped}?idHuesped=${idHuesped}`;

    return new Promise<any>((resolve, reject) => {
        this.clienteApiHtppService.post(url, null).subscribe(
        (res: any) => {
            resolve(res)
        },
        (error: any) => reject(error)
        );
    });
}

public obtenerReservasPorMes(mes: number, anio: number): Promise<RespuestaBaseLista<ListadoReservas>> {

  const url = `${this.uriObtenerReservasPorMes}?mes=${mes}&anio=${anio}`;
  return new Promise<RespuestaBaseLista<ListadoReservas>>(resolve => {
    this.httpClient.get<RespuestaBaseLista<ListadoReservas>>(url).subscribe(res => {
      resolve(ListadoReservas.parseLista(res));
    });
  });
}

public anularReserva(body: any): Promise<RespuestaBaseDetalle<boolean>> {
    
  return new Promise<RespuestaBaseDetalle<boolean>>(resolve => {
    this.httpClient.post<RespuestaBaseDetalle<boolean>>(this.uriAnularReserva, body).subscribe(res => {
      resolve(res);
    });
  });
}

}
