<div class="wrapper">
    <div class="sidebar" data-color="white" data-background-color="black" data-image="./assets/img/sidebar-1.jpg">
        <app-sidebar></app-sidebar>
        <div class="sidebar-background" style="background-image: url(./assets/img/sidebar-1.jpg)"></div>
    </div>
    <div class="main-panel">
        <app-navbar></app-navbar>
        <router-outlet></router-outlet>
        <div *ngIf="isMaps('maps')">
            <app-footer></app-footer>
        </div>
    </div>
    <!-- <div _ngcontent-sbe-c88="" class="fixed-plugin">
        <div _ngcontent-sbe-c88="" class="dropdown show-dropdown">
          <a _ngcontent-sbe-c88="" href="#" data-toggle="dropdown" aria-expanded="true">
            <i _ngcontent-sbe-c88="" class="fa fa-cog fa-2x"></i>
          </a>
          <ul _ngcontent-sbe-c88="" class="dropdown-menu">
            <li _ngcontent-sbe-c88="" class="header-title"> Sidebar Filters</li>
            <li _ngcontent-sbe-c88="" class="adjustments-line">
              <a _ngcontent-sbe-c88="" href="javascript:void(0)" class="switch-trigger active-color">
                <div _ngcontent-sbe-c88="" class="ml-auto mr-auto">
                  <span _ngcontent-sbe-c88="" data-color="purple" class="badge filter badge-purple"></span>
                  <span _ngcontent-sbe-c88="" data-color="azure" class="badge filter badge-azure active"></span>
                  <span _ngcontent-sbe-c88="" data-color="green" class="badge filter badge-green"></span>
                  <span _ngcontent-sbe-c88="" data-color="orange" class="badge filter badge-orange"></span>
                  <span _ngcontent-sbe-c88="" data-color="danger" class="badge filter badge-danger"></span>
                  <span _ngcontent-sbe-c88="" data-color="rose" class="badge filter badge-rose"></span>
                </div>
                <div _ngcontent-sbe-c88="" class="clearfix"></div>
              </a>
            </li>
            <li _ngcontent-sbe-c88="" class="header-title">Sidebar Background</li>
            <li _ngcontent-sbe-c88="" class="adjustments-line">
              <a _ngcontent-sbe-c88="" href="javascript:void(0)" class="switch-trigger background-color">
                <div _ngcontent-sbe-c88="" class="ml-auto mr-auto">
                  <span _ngcontent-sbe-c88="" data-color="white" class="badge filter badge-white"></span>
                  <span _ngcontent-sbe-c88="" data-color="black" class="badge filter badge-black"></span>
                  <span _ngcontent-sbe-c88="" data-color="red" class="badge filter badge-danger active"></span>
                </div>
                <div _ngcontent-sbe-c88="" class="clearfix"></div>
              </a>
            </li>
            <li _ngcontent-sbe-c88="" class="adjustments-line">
              <a _ngcontent-sbe-c88="" href="javascript:void(0)" class="switch-trigger">
                <p _ngcontent-sbe-c88="">Sidebar Mini</p>
                <label _ngcontent-sbe-c88="" class="ml-auto">
                  <div _ngcontent-sbe-c88="" class="togglebutton switch-sidebar-mini">
                    <label _ngcontent-sbe-c88="">
                      <input _ngcontent-sbe-c88="" type="checkbox">
                      <span _ngcontent-sbe-c88="" class="toggle"></span>
                    </label>
                  </div>
                </label>
                <div _ngcontent-sbe-c88="" class="clearfix"></div>
                <div _ngcontent-sbe-c88="" class="ripple-container"></div>
              </a>
            </li>
            <li _ngcontent-sbe-c88="" class="adjustments-line">
              <a _ngcontent-sbe-c88="" href="javascript:void(0)" class="switch-trigger">
                <p _ngcontent-sbe-c88="">Sidebar Images</p>
                <label _ngcontent-sbe-c88="" class="switch-mini ml-auto">
                  <div _ngcontent-sbe-c88="" class="togglebutton switch-sidebar-image">
                    <label _ngcontent-sbe-c88="">
                      <input _ngcontent-sbe-c88="" type="checkbox" checked="">
                      <span _ngcontent-sbe-c88="" class="toggle"></span>
                    </label>
                  </div>
                </label>
                <div _ngcontent-sbe-c88="" class="clearfix"></div>
                <div _ngcontent-sbe-c88="" class="ripple-container"></div>
              </a>
            </li>
            <li _ngcontent-sbe-c88="" class="header-title">Images</li>
            <li _ngcontent-sbe-c88="" class="active">
              <a _ngcontent-sbe-c88="" href="javascript:void(0)" class="img-holder switch-trigger">
                <img _ngcontent-sbe-c88="" src="./assets/img/sidebar-1.jpg" alt="">
              </a>
            </li>
            <li _ngcontent-sbe-c88="">
              <a _ngcontent-sbe-c88="" href="javascript:void(0)" class="img-holder switch-trigger">
                <img _ngcontent-sbe-c88="" src="./assets/img/sidebar-2.jpg" alt="">
              </a>
            </li>
            <li _ngcontent-sbe-c88="">
              <a _ngcontent-sbe-c88="" href="javascript:void(0)" class="img-holder switch-trigger">
                <img _ngcontent-sbe-c88="" src="./assets/img/sidebar-3.jpg" alt="">
              </a>
            </li>
            <li _ngcontent-sbe-c88="">
              <a _ngcontent-sbe-c88="" href="javascript:void(0)" class="img-holder switch-trigger">
                <img _ngcontent-sbe-c88="" src="./assets/img/sidebar-4.jpg" alt="">
              </a>
            </li>
            <li _ngcontent-sbe-c88="" class="button-container">
              <div _ngcontent-sbe-c88="">
                <button _ngcontent-sbe-c88="" data-toggle="modal" data-target="#buy" class="btn btn-info btn-block btn-fill"> Download Free </button>
              </div>
            </li>
            <li _ngcontent-sbe-c88="" class="button-container">
              <div _ngcontent-sbe-c88="">
                <button _ngcontent-sbe-c88="" data-toggle="modal" data-target="#buy" class="btn btn-warning btn-block btn-fill"> Buy Pro </button>
              </div>
            </li>
            <li _ngcontent-sbe-c88="" class="header-title">Thank you for 95 shares!</li>
            <li _ngcontent-sbe-c88="" class="button-container">
              <button _ngcontent-sbe-c88="" id="twitter" class="btn btn-social btn-twitter btn-round sharrre">
                <i _ngcontent-sbe-c88="" class="fa fa-twitter"></i> · 45
              </button>
              <button _ngcontent-sbe-c88="" id="facebook" class="btn btn-social btn-facebook btn-round sharrre">
                <i class="fa fa-facebook-square"></i> · 50
              </button>
            </li>
          </ul>
        </div>
    </div> 
    </div> -->   
</div>