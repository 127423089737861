<router-outlet></router-outlet>
<!--<ng-container *ngIf="Autenticado; else NoSession">
    <app-menu-lateral>
        <ng-container Body>
          <router-outlet *ngIf="!loading"></router-outlet>
      </ng-container>
    </app-menu-lateral>
</ng-container>
<ng-template #NoSession>
    <router-outlet></router-outlet>
</ng-template>-->
<!--<div
  style="position: fixed; height: 100vh; width: 100%; opacity: 0.5; background-color: white; z-index: 1000; text-align: center; padding-top:300px"
  *ngIf="loading">
  <li class="fa fas fa-sync fa-spin"></li> Cargando
</div>-->
<ngx-spinner type="ball-scale-multiple"></ngx-spinner>