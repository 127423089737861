import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ClienteApiHttpService } from './cliente-api-http.service';
import { Router, UrlSerializer } from '@angular/router';
import { sp } from '@pnp/sp';
import pnp from '@pnp/pnpjs';
import { Deferred } from 'ts-deferred';
import { Constantes } from 'src/app/shared/utils/Constantes';
import { RespuestaBaseDetalle, RespuestaBaseLista } from '../models/base/RespuestaBase';
import { HttpClient } from '@angular/common/http';
import { Evento } from '../models/response/evento';
import { ListadoEventos } from '../models/response/listado-eventos';
import { Funciones } from '../utils/Funciones';

@Injectable({ providedIn: 'root' })
export class EventosService {
  private uriRegistrarEvento: string = environment.apiUrlBackEnd + '/api/evento/registrar-evento';
  private uriModificarEvento: string = environment.apiUrlBackEnd + '/api/evento/modificar-evento';
  private uriObtenerEvento: string = environment.apiUrlBackEnd + '/api/evento/obtener-evento';
  private uriExportarParticipantesEvento: string = environment.apiUrlBackEnd + '/api/evento/exportar-participantes-evento';
  private uriEliminarEvento: string = environment.apiUrlBackEnd + '/api/evento/eliminar-evento';

  constructor(
    private clienteApiHtppService: ClienteApiHttpService,
    private httpClient: HttpClient,
    private router: Router,
    private serializer: UrlSerializer) {
    /*sp.setup({
      sp: {
        baseUrl: `${environment.proxyUrl}`
      }
    });*/
  }

  public registrarEvento(body: any): Promise<RespuestaBaseDetalle<string>> {
    
    return new Promise<RespuestaBaseDetalle<string>>(resolve => {
      this.httpClient.post<RespuestaBaseDetalle<string>>(this.uriRegistrarEvento, body).subscribe(res => {
        resolve(res);
      });
    });
  }

  public obtenerEvento(id: string): Promise<RespuestaBaseDetalle<Evento>> {

    const url = `${this.uriObtenerEvento}?id=${id}`;
    return new Promise<RespuestaBaseDetalle<Evento>>(resolve => {
      this.httpClient.get<RespuestaBaseDetalle<Evento>>(url).subscribe(res => {
        resolve(res);
      });
    });
  }

  public modificarEvento(body: any): Promise<RespuestaBaseDetalle<string>> {
    
    return new Promise<RespuestaBaseDetalle<string>>(resolve => {
      this.httpClient.post<RespuestaBaseDetalle<string>>(this.uriModificarEvento, body).subscribe(res => {
        resolve(res);
      });
    });
  }

  public exportarParticipantesEvento(body: any, nombreArchivo: string): Promise<string>
  {
    return new Promise<string>((resolve, reject) => {      
      this.httpClient.post(this.uriExportarParticipantesEvento, body).subscribe(
        (resultado: any) => {
          const blob = Funciones.obtenerBlobDesdeBase64(resultado.data);            
          const url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          a.download = nombreArchivo;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
          resolve(nombreArchivo);
        },
        (error: any) => reject(error)
      );
    });
  }

  public eliminarEvento(idEvento: string): Promise<boolean> {

    const url = `${this.uriEliminarEvento}?idEvento=${idEvento}`;

    return new Promise<any>((resolve, reject) => {
        this.clienteApiHtppService.post(url, null).subscribe(
        (res: any) => {
            resolve(res)
        },
        (error: any) => reject(error)
        );
    });
  }
  
}
