export enum State {
  Active = "Active",
  Inactive = "Inactive",
}

export enum ModalType {
  Confirm = "confirm",
  Error = "error",
  Warning = "warning",
  WarningWithActions = "warningWithActions",
  Success = "success",
  Comment = "comment",
}

export enum ServiciosWebAPI {

}
