import { Component, OnInit, ElementRef, ViewChild, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { FormularioBase } from 'src/app/shared/pages/FormularioBase';
import { MasterService } from 'src/app/shared/services/master.service';
import { UtilService } from 'src/app/shared/services/util.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr'
import * as $ from 'jquery';
import { Funciones } from 'src/app/shared/utils/Funciones';
import { RegistroParticipante } from 'src/app/shared/models/request/registro-participante';
import { ReservasService } from 'src/app/shared/services/reservas.service';
import { RespuestaBaseDetalle } from 'src/app/shared/models/base/RespuestaBase';
import { EventosService } from 'src/app/shared/services/eventos.service';
import { esEmailValido } from 'src/app/shared/utils/Util';

@Component({
  selector: 'app-nuevo-participante',
  templateUrl: './nuevo-participante.component.html',
  styleUrls: ['./nuevo-participante.component.css']
})
export class NuevoParticipanteComponent extends FormularioBase implements OnInit  {

  registroRealizado = false;
  sinIdentificadorEvento = false;
  mensajeErrorPagina = '';
  idEvento = '';
  nombreEvento = '';
  descripcionEvento = '';
  rutaBanner = '';
  nombreEmpresa = '';
  datosArchivo = null;
  mostrarPlaca = false;
  mostrarMensajeObligatorio = false;
  mostrarMensajeErrorEmail = false;
  ItemsPreguntas = [];
  autorizacionDatos = false;

  nombreControles = {
    nombres: 'nombres',
    apellidos: 'apellidos',
    numeroDocumento: 'numeroDocumento',
    correo: 'correo',
    placa: 'placa',
    celular: 'celular',
    autorizacion: 'autorizacion',
    nombreArchivo: 'nombreArchivo'
  };

  constructor(
    public dialog: MatDialog,
    public route: ActivatedRoute,
    public router: Router,
    public spinner: NgxSpinnerService,
    public utilService: UtilService,
    public masterService: MasterService,
    public reservasService: ReservasService,
    public eventosService: EventosService,
    private toastr: ToastrService
  ) {
    super('nuevo-participante', dialog, route, router, spinner, utilService, masterService);
  }

  async ngOnInit() {
    const eventoId = this.obtenerParametro('evento');

    if (eventoId == '')
    {
      this.sinIdentificadorEvento = true;
      this.mensajeErrorPagina = '¡La página ingresada no corresponde a un evento válido !';      
    }
    else
    {
      let resultado = await this.eventosService.obtenerEvento(eventoId);

      if(resultado.exito)
      {
        if(resultado.data.estado == 'Registrado')
        {
          console.dir(resultado.data);
          //this.form.get('eventoId')?.setValue(eventoId);
          this.idEvento = eventoId;
          this.nombreEvento = resultado.data.nombre;
          this.descripcionEvento = resultado.data.descripcion;
          this.rutaBanner = resultado.data.rutaBanner;
          this.nombreEmpresa = resultado.data.nombreEmpresa;

          this.ItemsPreguntas = resultado.data.listadoPreguntas.sort((a, b) => a.orden - b.orden);
          console.dir(this.ItemsPreguntas);

          this.ItemsPreguntas.forEach(element => {
            if(element.tipoPregunta === 'Varias Opciones')
            {
              element.valoresPregunta = !Funciones.esUndefinedNullOrEmpty(element.valoresPregunta) ? element.valoresPregunta.split('|') : [];
            }
          });

          //if(eventoId.toUpperCase() == '7213B8BE-8071-45EB-A5A2-89FEADF555A9') //DESARROLLO
          /*if(eventoId.toUpperCase() == 'E75F388B-8710-40BF-94A4-71A703C0295E') //PUBLICADO
          {
            this.mostrarPlaca = true;
            this.form.controls['placa'].validator = Validators.required;
            this.form.controls['placa'].updateValueAndValidity();
          }*/          
        }
        else
        {
          this.sinIdentificadorEvento = true;
          this.mensajeErrorPagina = '¡El evento ya no se encuentra disponible para el registro de participantes !';  
        }        
      }
      else
      {
        this.sinIdentificadorEvento = true;
        this.mensajeErrorPagina = '¡La página ingresada no corresponde a un evento válido !';
      }      
    }
  }

  obtenerParametro(parametro: string): any {
    const valor = this.route.snapshot.queryParams[parametro];

    if (!valor) {
      return '';
    }

    return valor;
  }

  onChangeInput(item: any, event: any): void {

    for (let i = 0; i < this.ItemsPreguntas.length; i++) {

      if (this.ItemsPreguntas[i].id === item.id) {
        this.ItemsPreguntas[i].respuestaPregunta = event.target.value;
      }
    }
  }

  onClickFileUpload(fileNameInput: string)
  {
    $('#' + fileNameInput).click();
  }

  async onFileUpload(fileNameInput: string, item: any, event: any): Promise<any>
  {
    if (event.target.files.length > 0) {

      const archivo = event.target.files[0];

      if (!Funciones.esArchivoExtensionValida(["jpg","png","jpeg"], archivo.name)) 
      {
        this.toastr.warning('Solo debe seleccionar una imagen de tipo png, jpg, jpeg', '¡Validación!');

        $('#' + fileNameInput).val('');
        return;
      }

      const archivoSize = archivo.size / 1024 / 1024;

      if (archivoSize > 2)
      {
        this.toastr.warning('Debe subir una imagen con un tamaño no mayor a 2MB', '¡Validación!');

        $('#' + fileNameInput).val('');
        return;
      }

      this.mostrarProgreso();

      Funciones.convertirArrayByte(archivo).then((resultByteArchivo: any) => {
        const byteArchivo: any = resultByteArchivo;

        const datosArchivo = {          
          NombreArchivo: Funciones.reemplazarNombreArchivo(archivo.name),
          BytesArchivo: byteArchivo
        };

        for (let i = 0; i < this.ItemsPreguntas.length; i++) {
          if (this.ItemsPreguntas[i].id === item.id) {
            this.ItemsPreguntas[i].nombreArchivo = archivo.name;
            this.ItemsPreguntas[i].respuestaPregunta = datosArchivo;
          }
        }

        $('#' + fileNameInput).val('');
        
        this.ocultarProgreso();
      })
      
    }
  }
  
  onEnviarClick(): void {

    this.mostrarMensajeObligatorio = true;   

    let datosIncompletos = false;

    this.ItemsPreguntas.forEach(element => {

      if(element.obligatorio && Funciones.esUndefinedNullOrEmpty(element.respuestaPregunta))
      {
        datosIncompletos = true;
      }

      if(element.obligatorio && 
         element.tipoDato === 'Correo' && 
         !Funciones.esUndefinedNullOrEmpty(element.respuestaPregunta) &&
         !esEmailValido(element.respuestaPregunta))
      {
        datosIncompletos = true;
      }
      
    });

    if (datosIncompletos) {
      this.toastr.warning('Ingrese los campos obligatorios.', '¡Validación!');
      return;
    }

    if (!this.autorizacionDatos) {
      this.toastr.warning('Debe marcar la casilla de autorización de tratamiento de sus datos.', '¡Validación!');
      return;
    }
    
    this.mostrarProgreso();

    let datosParticipante = { eventoId : this.idEvento } as RegistroParticipante;

    let respuestas = [];

    this.ItemsPreguntas.forEach(element => {
      respuestas.push({ 
        nombrePregunta: element.nombrePregunta,
        tipoPregunta: element.tipoPregunta,
        tipoDato: element.tipoDato,
        orden: element.orden,
        respuestaPregunta: element.tipoPregunta !== 'Subir Archivo' ? element.respuestaPregunta : '',
        respuestaPreguntaArchivo: element.tipoPregunta === 'Subir Archivo' ? element.respuestaPregunta : null
      });
    });

    datosParticipante.respuestas = respuestas;

    this.reservasService.registrarParticipante(datosParticipante)
      .then((result: RespuestaBaseDetalle<string>) => {
        this.ocultarProgreso();

        if (!result.exito)
        {
          this.toastr.warning('Se encontró un problema en el registro del participante', '¡Error!');
          return;
        }
        else
        {
          if(result.data === 'OK')
          {
            this.registroRealizado = true;
          }
          else
          {
            this.toastr.warning(result.data, 'Advertencia!', {
              timeOut: 3000
            });
          }          
        }
      })
      .catch((error: any) => {
        this.toastr.warning('Se encontró un problema en el registro del participante', '¡Error!');
        this.ocultarProgreso();
      });
  }

  public ValidarSoloEmail(event: any, valor: any): boolean
  {
    if(Funciones.esUndefinedNullOrEmpty(valor)) 
    {
      this.mostrarMensajeErrorEmail = false;
      return false;
    }

    this.mostrarMensajeErrorEmail = !esEmailValido(valor);
    return this.mostrarMensajeErrorEmail;
  }

  public esUndefinedNullOrEmpty(valor: any)
  {
    return Funciones.esUndefinedNullOrEmpty(valor);
  }

  onChangeRadioButtom(item: any, event: any): void {

    for (let i = 0; i < this.ItemsPreguntas.length; i++) {

      if (this.ItemsPreguntas[i].id === item.id) {
        this.ItemsPreguntas[i].respuestaPregunta = event.value;
      }
    }
  }

}
