
export class CodigoDescuento {
    
    Id: string;
    Codigo: string;
    Monto: number;    
    Motivo: string;
    Estado: string;

    constructor() {
        this.Id = '';
        this.Codigo = '';
        this.Monto = 0;
        this.Motivo = '';
        this.Estado = '';
    }

    public static parse(element: any): CodigoDescuento
    {
        const registro = new CodigoDescuento();

        if(element != null)
        {
            registro.Id = element.id;
            registro.Codigo = element.codigo;
            registro.Monto = element.monto;
            registro.Motivo = element.motivo;
            registro.Estado = element.estado;
        }        
        return registro;
    }
}