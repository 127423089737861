<div class="nav-bar-black">
    <div>
        <a icon-button routerLink="/home">
            <!--<img src="assets/inn/logo.png" height="56px" alt="">-->
            <img src="assets/img/logo-aire.png" height="56px" alt="">
        </a>
    </div>
    <div class="buscador col-lg-6">
        <mat-form-field class="w-100" appearance="outline">                
            <input matInput [(ngModel)]="contenido" placeholder="Buscar Alojamientos, Experiencias, Eventos, etc...">
            <mat-icon matSuffix (click)="onBuscar()">search</mat-icon>
        </mat-form-field>
    </div>
    <div class="d-flex align-items-center">
        <!--<button mat-stroked-button class="btn-stroked-perfil mr-8" *ngIf="sinLogueo" routerLink="/web/inicio-sesion">Login  <mat-icon>account_circle</mat-icon></button>-->
            <button mat-stroked-button class="btn-primario mr-8" *ngIf="sinLogueo" (click)="onIrInicioSesion()"
            >Login  <mat-icon>account_circle</mat-icon></button>
        <div>
            <button mat-flat-button class="btn-primario" *ngIf="sinLogueo"
            (click)="onIrRegistrarse()">Registrarse</button>
        </div>
        <div *ngIf="!sinLogueo" class="perfil d-flex align-items-center">
            {{nombreUsuario}} <button mat-icon-button [matMenuTriggerFor]="menu"> <mat-icon>account_circle</mat-icon> </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item routerLink="/admin/usuario">
                    <span>Perfil</span>
                </button>
                <button mat-menu-item routerLink="/admin/listado-reservas">
                    <span>Reservas</span>
                </button>
                <button mat-menu-item (click)="onCerrarSesion()">
                    <span>Cerrar Sesión</span>
                </button>
            </mat-menu>
        </div>            
    </div>
</div>

<div class="nav-bar-mob">

    <div class="d-flex justify-content-between mb-3">
        <div>
            <a icon-button routerLink="/home">
                <!--<img src="assets/inn/logo.png" height="56px" alt="">-->
                <img src="assets/img/logo-aire.png" height="56px" alt="">
            </a>
        </div>
        <div>
            <div class="d-flex align-items-center">
                <button mat-stroked-button class="btn-primario mr-8" *ngIf="sinLogueo" 
                    (click)="onIrInicioSesion()">Login  <mat-icon>account_circle</mat-icon>
                </button>        
                <div>
                    <button mat-flat-button class="btn-primario" *ngIf="sinLogueo"
                    (click)="onIrRegistrarse()">Registrarse</button>
                </div>
            </div>
        </div>
        <div *ngIf="!sinLogueo" class="perfil d-flex align-items-center">
            {{nombreUsuario}} <button mat-icon-button [matMenuTriggerFor]="menu"> <mat-icon>account_circle</mat-icon> </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item routerLink="/admin/usuario">
                    <span>Perfil</span>
                </button>
                <button mat-menu-item routerLink="/admin/listado-reservas">
                    <span>Reservas</span>
                </button>
                <button mat-menu-item (click)="onCerrarSesion()">
                    <span>Cerrar Sesión</span>
                </button>
            </mat-menu>
        </div>
    </div>

    <div>
        <div class="buscador col-lg-12">
            <mat-form-field class="w-100" appearance="outline">                    
                <input matInput [(ngModel)]="contenido" placeholder="Buscar Eventos, Hospedajes, Experiencias, etc...">
                <mat-icon matSuffix (click)="onBuscar()">search</mat-icon>
            </mat-form-field>
        </div>
    </div>
</div>

<!--<ng-content select="[Body]"></ng-content>-->
<router-outlet></router-outlet>

<div class="joinchat joinchat--left joinchat--show"
    [ngClass]="{ '': !AbrirModalWhatssapp, 'joinchat--chatbox': AbrirModalWhatssapp }"
    data-settings="{&quot;telephone&quot;:&quot;51925127336&quot;,&quot;mobile_only&quot;:false,&quot;button_delay&quot;:3,&quot;whatsapp_web&quot;:false,&quot;qr&quot;:false,&quot;message_views&quot;:2,&quot;message_delay&quot;:10,&quot;message_badge&quot;:false,&quot;message_send&quot;:&quot;Hola Necesito más información&quot;,&quot;message_hash&quot;:&quot;cf451209&quot;}" style="--peak: url(#joinchat__message__peak);">
    <div class="joinchat__button" (click)="AbrirModalWhatssapp = !AbrirModalWhatssapp">
        <div class="joinchat__button__open"></div>
        <div class="joinchat__button__sendtext" (click)="onAbrirChat()">Abrir chat</div>
        <svg class="joinchat__button__send" width="60" height="60" viewBox="0 0 400 400" stroke-linecap="round" stroke-width="33">
            <path class="joinchat_svg__plain" d="M168.83 200.504H79.218L33.04 44.284a1 1 0 0 1 1.386-1.188L365.083 199.04a1 1 0 0 1 .003 1.808L34.432 357.903a1 1 0 0 1-1.388-1.187l29.42-99.427"></path>
            <path class="joinchat_svg__chat" d="M318.087 318.087c-52.982 52.982-132.708 62.922-195.725 29.82l-80.449 10.18 10.358-80.112C18.956 214.905 28.836 134.99 81.913 81.913c65.218-65.217 170.956-65.217 236.174 0 42.661 42.661 57.416 102.661 44.265 157.316"></path>
        </svg>
        <div class="joinchat__tooltip">
            <div>¿Necesitas ayuda?</div>
        </div>
    </div>
    <div class="joinchat__box">
        <div class="joinchat__header">
            <span class="joinchat__header__text">Aire Simbal</span>
            <div class="joinchat__close" title="Cerrar" (click)="AbrirModalWhatssapp = false"></div>
        </div>
        <div class="joinchat__box__scroll">
            <div class="joinchat__box__content">
                <div class="joinchat__message">Hola<br>en qué podemos ayudarle?</div>				
            </div>
        </div>
    </div>
    <svg style="width:0;height:0;position:absolute"><defs><clipPath id="joinchat__message__peak"><path d="M17 25V0C17 12.877 6.082 14.9 1.031 15.91c-1.559.31-1.179 2.272.004 2.272C9.609 18.182 17 18.088 17 25z"></path></clipPath></defs></svg>
</div>

<div class="footer">
    <div class="container">
        <div class="row">
        <div class="col-lg-2 mb-3">
            <img src="assets/inn/logo.png" height="90" alt="">
        </div>
        <div class="col-lg-4">
            <div class="lista">
                <ul>
                    <li> <a href="/politicas">Políticas, Términos y condiciones</a></li>                      
                    <li> <a href="/contacto">Contacto</a></li>
                </ul>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="redes">
                <h6>Encuentrános en nuestras redes sociales</h6>
                <a href="https://www.instagram.com/aire.simbal/" class="mr-8" target="_blank"><img src="assets/inn/instagram.png" alt=""></a>
                <a href="https://www.facebook.com/airesimbal.peru/" class="mr-8" target="_blank"><img src="assets/inn/facebook.png" alt=""></a>
                <a href="https://youtube.com/@airesimbal?si=JKOwuBjRdslU8Q91" class="mr-8" target="_blank"><img src="assets/inn/youtube.png" alt=""></a>
                <a href="https://x.com/AireSimbal?s=20" class="mr-8" target="_blank"><img src="assets/inn/twitter.png" alt=""></a>
                <a href="https://tiktok.com/@airesimbal" class="mr-8" target="_blank"><img src="assets/inn/tiktok.png" alt=""></a>
            </div>
        </div>     
        <div class="col-lg-4 libro" *ngIf="false">
        <a href=""><img src="assets/inn/libro.png" alt=""></a> 
        </div>
        </div>
    </div>   
    <div class="subfooter">
        © 2023 INN Events - Todos los derechos reservados
    </div>
</div>